import { apiSlice } from "../../api/apiSlice";
import moment from "moment";

export const groupsAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllGroups: builder.query({
      query: ({ role, id, page = 1, limit = 10 }) => {
        let obj = {
          url: `/groups/all?page=${page}&limit=${limit}`,
        };
        switch (role) {
          case "DOCTOR":
            obj = {
              url: `/groups/filter?page=${page}&limit=${limit}`,
              method: "POST",
              body: {
                filter: {
                  participants: id,
                },
              },
            };
            break;

          case "PATIENT":
            obj = {
              url: `/groups/filter?page=${page}&limit=${limit}`,
              method: "POST",
              body: {
                filter: {
                  participants: id,
                },
              },
            };
            break;

          default:
            break;
        }
        return obj;
      },
      providesTags: ["Groups"],
    }),
    filterGroups: builder.query({
      query: ({ filter, page = 1, limit = 10 }) => {
        let obj = {
          url: `/groups/filter?page=${page}&limit=${limit}`,
          method: "POST",
          body: {
            filter,
          },
        };
        return obj;
      },
      providesTags: ["FilteredGroups"],
    }),
    getGroupsByDate: builder.query({
      query: ({
        role,
        id,
        startDate = moment().startOf("month").format(),
        endDate = moment().endOf("month").format(),
      }) => {
        let obj = {
          url: `/groups/filter?page=${0}&limit=${0}`,
          method: "POST",
          body: {
            filter: {
              participants: id,
              startDate: { $gte: startDate },
              endDate: { $lte: endDate },
            },
          },
        };

        return obj;
      },
      providesTags: ["Groups"],
    }),
    getGroupById: builder.query({
      query: (id) => {
        return {
          url: `/groups/${id}`,
        };
      },
      providesTags: ["SingleGroup"],
    }),
    updateGroupInfo: builder.mutation({
      query: (credentials) => {
        let { _id, ...rest } = credentials;
        let payload = rest;

        return {
          url: `/groups/${_id}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["Groups", "SingleGroup"],
    }),
    addGroup: builder.mutation({
      query: (credentials) => {
        let payload = credentials;
        return {
          url: `/groups/create`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Groups"],
    }),
    deleteGroup: builder.mutation({
      query: (credentials) => {
        return {
          url: `/groups/${credentials._id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Groups"],
    }),
    subscribeToGroup: builder.mutation({
      query: (credentials) => {
        let payload = credentials;
        return {
          url: `/groups/subscribeToGroup`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Groups", "User"],
    }),
  }),
});

export const {
  useGetAllGroupsQuery,
  useFilterGroupsQuery,
  useGetGroupByIdQuery,
  useUpdateGroupInfoMutation,
  useAddGroupMutation,
  useDeleteGroupMutation,
  useSubscribeToGroupMutation,
  useGetGroupsByDateQuery,
} = groupsAPISlice;
