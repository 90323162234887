import { apiSlice } from "../../api/apiSlice";

export const packsAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPacks: builder.query({
      query: (page = 1, limit = 10) => ({
        url: `/packs/all?page=${page}&limit=${limit}`,
      }),
      providesTags: ["Packs"],
    }),
    checkPackAlreadyPurchased: builder.mutation({
      query: (credentials) => {
        let payload = credentials;
        let obj = {
          url: `/packSubscription/checkPackAlreadyPurchased`,
          method: "POST",
          body: payload,
        };
        return obj;
      },
    }),
    getPayementCheckoutSession: builder.query({
      query: (packId) => ({
        url: `/packs/createCheckoutSession`,
        method: "POST",
        body: { packId },
      }),
      providesTags: ["Packs"],
    }),
    linkGroupToPackSubscription: builder.mutation({
      query: ({ packSubscriptionId, groupId }) => ({
        url: `/packSubscription/${packSubscriptionId}`,
        method: "PUT",
        body: { group: groupId },
      }),
      providesTags: ["Sessions", "User"],
    }),
    getSessionStatus: builder.query({
      query: (sessionId) => ({
        url: `/packs/sessionStatus`,
        method: "POST",
        body: { sessionId },
      }),
      providesTags: ["Packs"],
    }),
    getPackById: builder.query({
      query: (id) => {
        return {
          url: `/packs/${id}`,
        };
      },
    }),
    linkPackToPatient: builder.mutation({
      query: (credentials) => {
        let payload = credentials;
        return {
          url: `/packs/linkPackToPatient`,
          method: "POST",
          body: payload,
        };
      },
    }),
  }),
});

export const {
  useGetAllPacksQuery,
  useGetPackByIdQuery,
  useGetPayementCheckoutSessionQuery,
  useGetSessionStatusQuery,
  useLinkPackToPatientMutation,
  useLinkGroupToPackSubscriptionMutation,
  useCheckPackAlreadyPurchasedMutation
} = packsAPISlice;
