import React from "react";

const Footer = ({ isAuthFooter = false }) => {
  return (
    <div className={`${isAuthFooter ? "auth-footer" : "footer"}`}>
      <div className="copyright">
        <p>
          <strong>INSTITUT MÉDICAL DU SOMMEIL</strong> <br></br> ©{" "}
          {new Date().getFullYear()} Tous droits réservés
        </p>
      </div>
    </div>
  );
};

export default Footer;
