import React, { Fragment, useEffect, useState } from "react";
//** Import Image */
import avatarEmpty from "../../../images/avatar_empty.png";

import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import DateTimePicker from "../../components/Forms/Pickers/MetarialDateAndTime";
import { useGetAllDoctorsQuery } from "../../../features/doctors/doctorsAPISlice";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useGetAllPatientsQuery } from "../../../features/patients/patientsAPISlice";
import moment from "moment";
import Select from "react-select";
import {
  useAddSessionMutation,
  useSubscribeToSessionMutation,
} from "../../../features/sessions/sessionsAPISlice";
import { toast } from "react-toastify";
import { apiSlice } from "../../../api/apiSlice";

const SessionAdd = () => {
  const [user, _, subscriptions] = useOutletContext();

  const {
    register,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm();
  const [addSession, { addSessionIsLoading }] = useAddSessionMutation();
  const [subscribeToSession, { isLoading: isSubscribeToSessionLoading }] =
    useSubscribeToSessionMutation();
  const navigate = useNavigate();
  const [participants, setParticipants] = useState([]);
  const [participantsLoading, setParticipantsLoading] = useState(true);
  const { data: doctors, isLoading: doctorsIsLoading } = useGetAllDoctorsQuery(
    0,
    0
  );
  const { data: patients, isLoading: patientsIsLoading } =
    useGetAllPatientsQuery(0, 0);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      let activeSub = subscriptions
        ? subscriptions[subscriptions.length - 1]
        : null;

      let obj = {
        ...data,
      };

      if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
        toast.error(
          "Une ou plusieurs informations fournies ne sont pas valides!"
        );
        return;
      }

      if (participants && participants.length > 0) {
        let _participants = participants.reduce(
          (acc, curr) => [...acc, curr["value"]],
          []
        );
        if (user.role === "DOCTOR" || user.role === "PATIENT") {
          _participants = [..._participants, user._id];
        }
        obj = {
          ...obj,
          participants: _participants,
        };
      } else {
        let arr = [];
        if (user.role === "DOCTOR" || user.role === "PATIENT") {
          arr = [user._id];
        }
        obj = {
          ...obj,
          participants: arr,
        };
      }

      if (moment(startDate).isValid()) {
        obj = {
          ...obj,
          startDate: moment(startDate).format(),
        };
      }

      if (obj.participants.length > 0) {
        let doctorsIds = doctors.users.reduce(
          (acc, curr) => [...acc, curr["_id"]],
          []
        );
        let doctorId = obj.participants.filter((elt) =>
          doctorsIds.includes(elt)
        );

        if (doctorId.length === 0) {
          toast.error("Au moins 1 psychologue doit être ajouté!");
          return;
        }
        // const availabilityData = await validateDoctorAvailability({
        //   doctoruserId: user._id,
        //   startDate: obj.startDate,
        // }).unwrap();

        // if (!availabilityData.availability) {
        //   toast.error(
        //     "Le psychologue n'est pas disponible pour cette période!"
        //   );
        //   return;
        // }
      }

      if (moment(endDate).isValid()) {
        obj = {
          ...obj,
          endDate: moment(endDate).format(),
        };
      }

      if (user.role === "ADMIN") {
        obj = {
          ...obj,
          type: data.type,
        };
      } else {
        obj = {
          ...obj,
          type: "Visio_Indiv",
        };
      }

      if (Object.values(obj).length > 1) {
        const sessionData = await addSession(obj).unwrap();

        if (activeSub) {
          await subscribeToSession({
            patientId: user.patient._id,
            sessionId: sessionData.session._id,
            packSubscriptionId: activeSub._id,
          }).unwrap();
        }

        dispatch(apiSlice.util.invalidateTags(["User"]));
        if (sessionData && sessionData.session._id) {
          navigate(`/seances/details/${sessionData.session._id}`);
        }
      }
    } catch (error) {
      console.log("onSubmit : ", error);
    }
  };

  const participantsLabelCreator = (role) => {
    switch (role) {
      case "DOCTOR":
        return "Patients";

      case "PATIENT":
        return "Psychologues";

      default:
        return "Participants";
    }
  };

  const participantsOptionsCreator = (role) => {
    switch (role) {
      case "DOCTOR":
        return [
          ...patients.users.map((elt) => ({
            label:
              elt.firstName || elt.lastName
                ? `${elt.firstName || ""} ${elt.lastName || ""}`
                : elt.email,
            value: elt.id,
          })),
        ];

      case "PATIENT":
        return [
          ...doctors.users.map((elt) => ({
            label:
              elt.firstName || elt.lastName
                ? `${elt.firstName || ""} ${elt.lastName || ""}`
                : elt.email,
            value: elt.id,
          })),
        ];

      default:
        return [
          ...doctors.users.map((elt) => ({
            label:
              elt.firstName || elt.lastName
                ? `${elt.firstName || ""} ${elt.lastName || ""}`
                : elt.email,
            value: elt.id,
          })),
          ...patients.users.map((elt) => ({
            label:
              elt.firstName || elt.lastName
                ? `${elt.firstName || ""} ${elt.lastName || ""}`
                : elt.email,
            value: elt.id,
          })),
        ];
    }
  };

  const participantsLoader = (role) => {
    let value = true;
    switch (role) {
      case "DOCTOR":
        value = patientsIsLoading;
        break;

      case "PATIENT":
        value = doctorsIsLoading;
        break;

      default:
        value = patientsIsLoading || doctorsIsLoading;
        break;
    }
    return value;
  };

  useEffect(() => {
    const val = participantsLoader(user.role);
    setParticipantsLoading(val);
  }, [user, patientsIsLoading, doctorsIsLoading]);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <div className="tab-content">
                    <div
                      id="profile-info"
                      className={`tab-pane fade active show`}
                    >
                      <div className="pt-3">
                        <div className="settings-form">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-row">
                              <div className="form-group col-md-12 row">
                                <div className="form-group col-md-6">
                                  <label>Titre</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    {...register("title")}
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Date de début</label>
                                  <DateTimePicker
                                    selectedDate={startDate}
                                    setSelectedDate={setStartDate}
                                    className="form-control cutom-date-picker"
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Date de fin estimée</label>
                                  <DateTimePicker
                                    selectedDate={endDate}
                                    setSelectedDate={setEndDate}
                                    className="form-control cutom-date-picker"
                                  />
                                </div>

                                {!participantsLoading && (
                                  <div className="form-group col-md-6">
                                    <label>
                                      {participantsLabelCreator(user.role)}
                                    </label>
                                    <Select
                                      defaultValue={participants}
                                      onChange={(e) => setParticipants(e)}
                                      options={participantsOptionsCreator(
                                        user.role
                                      )}
                                      isMulti
                                      placeholder={""}
                                      className="custom-multi-select"
                                    />
                                  </div>
                                )}

                                {user.role === "ADMIN" && (
                                  <div className="form-group col-md-6">
                                    <label>Type</label>
                                    <select
                                      className="form-control"
                                      {...register("type")}
                                    >
                                      <option value="Visio_Grouped">
                                        En Visio
                                      </option>
                                      <option value="OnSite_Grouped">
                                        Sur place
                                      </option>
                                    </select>
                                  </div>
                                )}

                                <div className="form-group  col-md-12">
                                  <label>Description</label>
                                  <textarea
                                    rows="3"
                                    cols="50"
                                    className="form-control"
                                    {...register("description")}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row justify-content-end">
                              {addSessionIsLoading ? (
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <button
                                  className="btn btn-primary text-right text-white"
                                  type="submit"
                                >
                                  Enregistrer
                                </button>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SessionAdd;
