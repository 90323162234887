import React, { useEffect, useState } from "react";

/// React router dom
import { Routes, Route, useOutletContext } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import ActionNotAuthorized from "./pages/ActionNotAuthorized";

/// Pulgin
import MainLayout from "./layouts/MainLayout";
import AuthLayout from "./layouts/AuthLayout";
import Dashboard from "./pages/Dashboard";
import Patients from "./pages/Patients";
import Sessions from "./pages/Sessions";
import SessionAdd from "./pages/Sessions/SessionAdd.js";
import SessionDetails from "./pages/Sessions/SessionDetails.js";
import Documents from "./pages/Documents";
import Factures from "./pages/Factures";
import Messages from "./pages/Messages";
import Packs from "./pages/Packs";
import ProtectedRoute from "../helpers/ProtectedRoute";
import ResetPassword from "./pages/ResetPassword";
import Doctors from "./pages/Doctors";
import PersonDetails from "./pages/PersonDetails";
import PersonAdd from "./pages/PersonAdd";
import Calendar from "./pages/Calendar";
import Profile from "./pages/Profile";
import VideoChat from "./pages/VideoChat";
import Checkout from "./pages/Packs/Checkout";
import CheckoutReturn from "./pages/Packs/CheckoutReturn";
import Planify from "./pages/Dashboard/Planify";
import Groups from "./pages/Groups";
import GroupDetails from "./pages/Groups/GroupDetails";
import GroupAdd from "./pages/Groups/GroupAdd";

const AuthRoutes = [
  { url: "register", element: <Registration /> },
  { url: "lock-screen", element: <LockScreen /> },
  { url: "login", element: <Login /> },
  { url: "forgot-password", element: <ForgotPassword /> },
  { url: "forgot-password-invalid", element: <ActionNotAuthorized /> },
  { url: "reset-password", element: <ResetPassword /> },
  { url: "creation-validation", element: <ResetPassword /> },
];

const ErrorRoutes = [
  { url: "error-400", element: <Error400 /> },
  { url: "error-403", element: <Error403 /> },
  { url: "error-404", element: <Error404 /> },
  { url: "error-500", element: <Error500 /> },
  { url: "non-autorise", element: <Error403 /> },
  { url: "error-503", element: <ActionNotAuthorized /> },
];

const AppRoutes = [
  { url: "", element: <Dashboard />, index: true },
  { url: "patients", element: <Patients /> },
  { url: "patients/details/:id", element: <PersonDetails /> },
  { url: "patients/ajouter", element: <PersonAdd /> },
  { url: "psychologues", element: <Doctors /> },
  { url: "psychologues/details/:id", element: <PersonDetails /> },
  { url: "psychologues/ajouter", element: <PersonAdd /> },
  { url: "seances", element: <Sessions /> },
  { url: "seances/details/:id", element: <SessionDetails /> },
  { url: "seances/ajouter", element: <SessionAdd /> },
  { url: "groupes", element: <Groups /> },
  { url: "groupes/details/:id", element: <GroupDetails /> },
  { url: "groupes/ajouter", element: <GroupAdd /> },
  { url: "documents", element: <Documents /> },
  { url: "factures", element: <Factures /> },
  { url: "messages", element: <Messages /> },
  { url: "discuter-avec-psycologue", element: <Messages /> },
  // { url: "packs", element: <Packs /> },
  // { url: "calendrier", element: <Calendar /> },
  { url: "calendrier", element: <Calendar /> },
  { url: "profil", element: <Profile /> },
];

const Markup = () => {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute />}>
        {AppRoutes.map((data, i) => (
          <Route
            key={i}
            path={data.url}
            index={data.index}
            element={<MainLayout component={data.element} />}
          />
        ))}
        <Route path={"checkout/:packId"} element={<Checkout />} />
        <Route
          path={"return"}
          element={<MainLayout component={<CheckoutReturn />} />}
        />
        {/* this is a grave non best practice code but it's sunday and i am really tired */}
        <Route
          path={"planifier"}
          // element={<MainLayout component={<Planify />} />}
          element={<MainLayout component={<CheckoutReturn />} />}
        />
      </Route>

      <Route path="consultation/:roomId" element={<VideoChat />} />

      {AuthRoutes.map((data, i) => (
        <Route
          key={i}
          path={data.url}
          element={<AuthLayout component={data.element} />}
        />
      ))}

      {ErrorRoutes.map((data, i) => (
        <Route key={i} path={data.url} element={data.element} />
      ))}

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default Markup;
