import moment from "moment";
import { apiSlice } from "../../api/apiSlice";

export const availabilitiesAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllAvailabilities: builder.query({
      query: (page = 1, limit = 10) => ({
        url: `/availabilities/all?page=${page}&limit=${limit}`,
      }),
      providesTags: ["Availabilities"],
    }),
    getAvailabilityById: builder.query({
      query: (id) => {
        return {
          url: `/availabilities/${id}`,
        };
      },
      providesTags: ["SingleAvailability"],
    }),
    updateAvailability: builder.mutation({
      query: (credentials) => {
        let { _id, ...rest } = credentials;
        let payload = rest;

        return {
          url: `/availabilities/${_id}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["Availabilities", "SingleAvailability", "User"],
    }),
    addAvailability: builder.mutation({
      query: (credentials) => {
        let payload = credentials;
        return {
          url: `/availabilities/create`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Availabilities"],
    }),
    deleteAvailability: builder.mutation({
      query: (credentials) => {
        return {
          url: `/availabilities/${credentials._id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Availabilities"],
    }),
    filterAvailability: builder.query({
      query: ({ filter, page = 1, limit = 10 }) => {
        let obj = {
          url: `/availabilities/filter?page=${page}&limit=${limit}`,
          method: "POST",
          body: {
            filter,
          },
        };

        return obj;
      },
      providesTags: ["FilteredAvailabilities"],
    }),
    getAvailabilitySelection: builder.mutation({
      query: (credentials) => {
        return {
          url: `/availabilities/getAvailabilitySelection`,
          method: "POST",
          body: credentials,
        };
      },
    }),
  }),
});

export const {
  useGetAllAvailabilitiesQuery,
  useGetAvailabilitySelectionMutation,
  useFilterAvailabilityQuery,
  useGetAvailabilityByIdQuery,
  useUpdateAvailabilityMutation,
  useAddAvailabilityMutation,
  useDeleteAvailabilityMutation,
} = availabilitiesAPISlice;
