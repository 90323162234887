import React, { Component, useEffect, useState } from "react";

/// Link
import { Link, useLocation, useOutletContext } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "@metismenu/react";
import { menuFinder } from "../../../helpers/menus";

export const MM = ({ children }) => {
  return (
    <div className="mm-wrapper">
      <MetisMenu>{children}</MetisMenu>
    </div>
  );
};

const SideBar = () => {
  const [user, _, subscriptions] = useOutletContext();
  let [menu, setMenu] = useState([]);
  const location = useLocation();

  useEffect(() => {
    setMenu(menuFinder(user.role, subscriptions));
  }, [subscriptions]);

  return (
    <div className="deznav">
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          {user &&
            user.role &&
            menu.map((elt, index) => {
              return (
                <li
                  key={index}
                  className={`sidebar-elt ${
                    location &&
                    ((elt.link === "/" && location.pathname == "/") ||
                      (elt.link !== "/" &&
                        location.pathname.includes(elt.link)))
                      ? "mm-active"
                      : ""
                  }`}
                >
                  <Link
                    to={`${elt.link}`}
                    className="ai-icon"
                    aria-expanded="false"
                  >
                    {elt.icon}
                    <span className="nav-text">{elt.item}</span>
                    {location &&
                      ((elt.link === "/" && location.pathname == "/") ||
                        (elt.link !== "/" &&
                          location.pathname.includes(elt.link))) && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          className="active-arrow"
                        >
                          <g clipPath="url(#clip0_1_575)">
                            <path
                              d="M23.725 11.3364C23.7248 11.3361 23.7245 11.3358 23.7242 11.3355L18.8256 6.4605C18.4586 6.09529 17.865 6.09665 17.4997 6.46368C17.1345 6.83067 17.1359 7.42425 17.5028 7.78949L20.7918 11.0625H0.9375C0.419719 11.0625 0 11.4822 0 12C0 12.5178 0.419719 12.9375 0.9375 12.9375H20.7917L17.5029 16.2105C17.1359 16.5757 17.1345 17.1693 17.4998 17.5363C17.865 17.9034 18.4587 17.9046 18.8256 17.5395L23.7242 12.6645C23.7245 12.6642 23.7248 12.6639 23.7251 12.6636C24.0923 12.2971 24.0911 11.7016 23.725 11.3364Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1_575">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                  </Link>
                </li>
              );
            })}
        </MM>
        <div className="copyright">
          <p>
            <strong>INSTITUT MÉDICAL DU SOMMEIL</strong> <br></br> ©{" "}
            {new Date().getFullYear()} Tous droits réservés
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
