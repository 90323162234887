import React, { useState } from "react";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function BasicTImePicker({ selectedDate, handleDateChange, id = "0-0" }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker
        okLabel="ok"
        clearLabel="Vider"
        cancelLabel="Annuler"
        label=""
        id={id}
        clearable
        format="HH:mm"
        value={selectedDate || new Date()}
        onChange={(e) => {
          handleDateChange(e, id);
        }}
        className="form-control cutom-date-picker"
        InputProps={{
          disableUnderline: true,
        }}
        readOnly={false}
        disabled={false}
      />
    </MuiPickersUtilsProvider>
  );
}

export default BasicTImePicker;
