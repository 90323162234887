import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useLoginMutation } from "../../features/auth/authAPISlice";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../features/auth/authSlice";
import miniLogo from "../../images/LOGO_IMS_MINI.png";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);

  const [login, { isLoading }] = useLoginMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      const userData = await login({
        email: data.email,
        password: data.password,
      }).unwrap();

      if (userData && userData.token) {
        let credsObj = {
          user: userData.user,
          token: userData.token,
        };
        if (
          userData.user.role === "PATIENT" &&
          userData.user.patient.subscriptions &&
          userData.user.patient.subscriptions.length > 0
        ) {
          credsObj = {
            ...credsObj,
            subscriptions: userData.user.patient.subscriptions,
          };
        }
        dispatch(setCredentials(credsObj));
        navigate("/");
      }
    } catch (error) {
      console.log("login : ", error);
    }
  };

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center auth-card">
          <div className="col-md-6 auth-card-content">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12 auth-card-logo">
                  <img src={miniLogo} alt="" />
                </div>
                <div className="col-xl-12">
                  <div className="auth-form">
                    <h4 className="text-center mb-4">
                      Connectez-vous à votre compte
                    </h4>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          {...register("email")}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          <strong>Mot de passe</strong>
                        </label>
                        <div className="input-group transparent-append">
                          <input
                            type={`${showPassword ? "text" : "password"}`}
                            className="form-control"
                            name="password"
                            {...register("password")}
                          />

                          <div
                            className="input-group-append"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <span className="input-group-text input-icon-right">
                              {showPassword ? (
                                <i className="fa-solid fa-eye"></i>
                              ) : (
                                <i className="fa-solid fa-eye-slash"></i>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-row d-flex justify-content-between mt-4 mb-2">
                        <div className="form-group">
                          <Link to="/forgot-password">
                            Mot de passe oublié ?
                          </Link>
                        </div>
                      </div>
                      <div className="text-center">
                        {isLoading ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <input
                            type="submit"
                            value="Se connecter"
                            className="btn btn-primary btn-block text-white"
                          />
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
