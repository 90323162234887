import { apiSlice } from "../../api/apiSlice";

export const messagesAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllRooms: builder.query({
      query: () => ({
        url: `/rooms/all`,
        method: "POST",
        body: {},
      }),
      providesTags: ["Rooms"],
    }),
    getCurrentRoom: builder.query({
      query: (id) => {
        return {
          url: `/rooms/${id}`,
        };
      },
      providesTags: ["CurrentRoom"],
    }),
    getRoomsByParticipants: builder.query({
      query: (payload) => {
        return {
          url: `/rooms/getRoomsByParticipants`,
          method: "POST",
          body: payload,
        };
      },
      providesTags: ["ParticipantsRooms"],
    }),
    createMessage: builder.mutation({
      query: (payload) => {
        return {
          url: `/messages/create`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Messages", "Rooms", "CurrentRoom","ParticipantsRooms"],
    }),
    createRoom: builder.mutation({
      query: (payload) => {
        return {
          url: `/rooms/create`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Rooms"],
    }),
  }),
});

export const {
  useGetAllRoomsQuery,
  useGetCurrentRoomQuery,
  useGetRoomsByParticipantsQuery,
  useCreateMessageMutation,
  useCreateRoomMutation,
} = messagesAPISlice;
