import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import authReducer from "./features/auth/authSlice";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { doctorsAPISlice } from "./features/doctors/doctorsAPISlice";
import { patientsAPISlice } from "./features/patients/patientsAPISlice";
import { sessionsAPISlice } from "./features/sessions/sessionsAPISlice";
import { documentsAPISlice } from "./features/documents/documentsAPISlice";
import { packsAPISlice } from "./features/packs/packsAPISlice";
import { invoicesAPISlice } from "./features/invoices/invoicesAPISlice";
import { availabilitiesAPISlice } from "./features/availabilities/availabilitiesAPISlice";

export const rootPersistConfig = {
  key: "creds",
  version: 1,
  storage,
};

const persistedRootReducer = persistReducer(rootPersistConfig, authReducer);

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: persistedRootReducer,
    doctors: doctorsAPISlice.reducer,
    patients: patientsAPISlice.reducer,
    sessions: sessionsAPISlice.reducer,
    documents: documentsAPISlice.reducer,
    packs: packsAPISlice.reducer,
    invoice: invoicesAPISlice.reducer,
    availabilities: availabilitiesAPISlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware),
  devTools: true,
});

const persistor = persistStore(store);

export { store, persistor };
