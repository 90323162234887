import React, { useEffect, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import ProfileDatatable from "../../components/table/ProfileDatatable";
import {
  useAddDocumentMutation,
  useDeleteDocumentMutation,
  useGetDocumentByParticipantQuery,
} from "../../../features/documents/documentsAPISlice";
import moment from "moment";
import DocumentViewer from "../../components/DocumentViewer";
import { Button, Dropdown, Modal } from "react-bootstrap";
import Select from "react-select";
import { useGetAllPatientsQuery } from "../../../features/patients/patientsAPISlice";
import { useGetAllGroupsQuery } from "../../../features/groups/groupsAPISlice";
import { useGetAllSessionsQuery } from "../../../features/sessions/sessionsAPISlice";
import { toast } from "react-toastify";

const filterOptions = [
  { label: "NOM (De A à Z)", value: { name: 1 } },
  { label: "NOM (De Z à A)", value: { name: -1 } },
  // { label: "SÉANCE (De A à Z)", value: { "session.title": 1 } },
  // { label: "SÉANCE (De Z à A)", value: { "session.title": -1 } },
  // { label: "CRÉÉ À (ordre décroissant)", value: { createdAt: -1 } },
  // { label: "CRÉÉ À (ordre croissant)", value: { createdAt: 1 } },
];

const initialFilterState = {
  label: "Filtrer par",
  value: { createdAt: -1 },
};

function Documents() {
  let [limit, setLimit] = useState(10);
  let [page, setPage] = useState(1);
  const [user] = useOutletContext();
  const [searchParams] = useSearchParams();
  const [files, setFiles] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [documentID, setDocumentID] = useState(null);
  const [addFilesModal, setAddFilesModal] = useState(false);
  const [addDocument] = useAddDocumentMutation();
  const [fileUsers, setFileUsers] = useState([]);
  const [fileSession, setFileSession] = useState(null);
  const [deleteDocument, { isLoading: deleteDocumentIsLoading }] =
    useDeleteDocumentMutation();
  const { data: patients, isLoading: isPatientsLoading } =
    useGetAllPatientsQuery(0, 0);
  const { data: groups } = useGetAllGroupsQuery({
    role: user.role,
    id: user._id,
    page: 0,
    limit: 0,
  });
  const { data: sessions } = useGetAllSessionsQuery({
    role: user.role,
    id: user._id,
    limit: 0,
    page: 0,
  });

  const sessionsOptions = () => {
    let sessionsArray = [];
    if (sessions && sessions.sessions.length > 0) {
      sessionsArray = [
        ...sessionsArray,
        ...sessions.sessions.map((elt) => ({
          type: "indiv",
          label: `Séance individuelle (${moment(elt["startDate"]).format(
            "DD/MM/YYYY HH:mm"
          )})`,
          value: elt["_id"],
        })),
      ];
    }

    if (groups && groups.groups && groups.groups.length > 0) {
      for (let grp of groups.groups) {
        let days = Object.keys(grp).filter((elt) => elt.includes("date_"));
        for (let index = 0; index < days.length; index++) {
          const element = days[index];
          sessionsArray = [
            ...sessionsArray,
            {
              type: "group",
              label: `${grp.title} (Séance ${element.replace(
                "date_",
                ""
              )} : ${moment(grp[element]).format("DD/MM/YYYY HH:mm")})`,
              value: `${grp["_id"]}___${index}`,
              groupDate: grp[element],
            },
          ];
        }
      }
    }

    return sessionsArray;
  };
  const [filterBy, setFilterBy] = useState(initialFilterState);
  let [sortObj, setSortObj] = useState({
    createdAt: -1,
  });
  let [filterObj, setFilterObj] = useState({
    users: user._id,
  });
  const [skip, setSkip] = useState(true);

  const [searchString, setSearchString] = useState("");

  const { data: documents, isLoading } = useGetDocumentByParticipantQuery(
    {
      page,
      limit,
      filter: filterObj,
      sort: sortObj,
    },
    {
      skip,
    }
  );

  const getDocumentUrl = (id) => {
    if (isLoading || !documents) {
      return null;
    }
    const docs = documents.documents;
    let doc = docs.find((elt) => elt._id === id);
    if (doc && doc.file) {
      return doc.file;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (searchParams.get("page")) {
      setPage(parseInt(searchParams.get("page")));
    }
    if (searchParams.get("limit")) {
      setLimit(parseInt(searchParams.get("limit")));
    }
  }, [searchParams]);

  useEffect(() => {
    if (user) {
      setSkip(false);
    }
  }, [user]);

  const searchDocuments = (value) => {
    try {
      setSearchString(value);
      setFilterObj((prevState) => ({
        ...prevState,
        gorupTitle: value,
        groupDate : value,
        name: value,
      }));
      setSkip(false);
    } catch (error) {
      console.log("err searchDocuments: ", error);
    }
  };

  const uploadFiles = async () => {
    try {
      if (
        (user.role !== "PATIENT" && fileUsers.length === 0) ||
        !fileSession ||
        files.length === 0
      ) {
        return toast.error(
          "Veuillez ajouter toutes les informations nécessaires!"
        );
      }

      for (let file of files) {
        let formData = new FormData();
        formData.append("name", `${Date.now()}-` + file.name);
        formData.append("document", file);
        if (fileSession.type === "group") {
          formData.append("group", fileSession.value.split("___")[0]);
          formData.append("groupDate", fileSession.groupDate);
        } else {
          formData.append("session", fileSession.value);
        }
        let array = [];
        if (user.role === "PATIENT") {
          if (user.patient.doctor && typeof user.patient.doctor === "object") {
            array = [user._id, user.patient.doctor._id];
          } else if (
            user.patient.doctor &&
            typeof user.patient.doctor === "string"
          ) {
            array = [user._id, user.patient.doctor];
          } else {
            array = [user._id];
          }
        }
        if (user.role === "DOCTOR" || user.role === "ADMIN") {
          array = [
            user._id,
            ...fileUsers.reduce((acc, curr) => [...acc, curr.value], []),
          ];
        }
        formData.append("users", JSON.stringify(array));

        await addDocument(formData).unwrap();
      }

      closeFileUploadModal();
    } catch (error) {
      console.log("onSubmit : ", error);
    }
  };

  const closeFileUploadModal = () => {
    setFiles([]);
    setFileUsers([]);
    setFileSession(null);
    setAddFilesModal(false);
  };

  const removeFileFromFileList = (file) => {
    let _files = [...files];
    let deletedFileIndex = files.findIndex((elt) => elt.name === file.name);
    if (deletedFileIndex > -1) {
      _files.splice(deletedFileIndex, 1);
    }
    setFiles(_files);
  };

  const participantsOptionsCreator = (role) => {
    return patients && patients.users.length > 0
      ? [
          ...patients.users.map((elt) => ({
            label:
              elt.firstName || elt.lastName
                ? `${elt.firstName || ""} ${elt.lastName || ""}`
                : elt.email,
            value: elt.id,
          })),
        ]
      : [];
  };

  const tableDataFormatter = () => {
    if (user.role === "PATIENT") {
      return {
        data: documents.documents.map((elt) => {
          if (elt.session) {
            return {
              _id: elt._id,
              name: elt.name,
              url: elt.file || "__",
              session: elt.session.startDate
                ? `Séance individuelle (${moment(elt.session.startDate).format(
                    "DD/MM/YYYY HH:mm"
                  )})`
                : "",
            };
          } else if (elt.group) {
            return {
              _id: elt._id,
              name: elt.name,
              url: elt.file || "__",
              session: elt.group.startDate
                ? `${elt.group.title} (Séance : ${moment(elt.groupDate).format(
                    "DD/MM/YYYY HH:mm"
                  )})`
                : "",
            };
          } else {
            return {
              _id: elt._id,
              name: elt.name,
              url: elt.file || "__",
              session: elt.groupDate
                ? moment(elt.groupDate).format("DD/MM/YYYY HH:mm")
                : "",
            };
          }
        }),
        columns: ["_id", "Nom", "Séance", "Action"],
      };
    } else {
      return {
        data: documents.documents.map((elt) => {
          if (elt.session) {
            return {
              _id: elt._id,
              name: elt.name,
              url: elt.file || "__",
              session: elt.session.startDate
                ? moment(elt.session.startDate).format("DD/MM/YYYY HH:mm")
                : "",
              patients: elt.users.map((elt) => ({
                label:
                  elt.firstName || elt.lastName
                    ? `${elt.firstName || ""} ${elt.lastName || ""}`
                    : elt.email,
                value: elt.id,
              })),
            };
          } else if (elt.group) {
            return {
              _id: elt._id,
              name: elt.name,
              url: elt.file || "__",
              session: elt.group.title
                ? `${elt.group.title} (Séance : ${moment(elt.groupDate).format(
                    "DD/MM/YYYY HH:mm"
                  )})`
                : "",
              patients: elt.users.map((elt) => ({
                label:
                  elt.firstName || elt.lastName
                    ? `${elt.firstName || ""} ${elt.lastName || ""}`
                    : elt.email,
                value: elt.id,
              })),
            };
          } else {
            return {
              _id: elt._id,
              name: elt.name,
              url: elt.file || "__",
              session: elt.groupDate
                ? moment(elt.groupDate).format("DD/MM/YYYY HH:mm")
                : "",
              patients: elt.users.map((elt) => ({
                label:
                  elt.firstName || elt.lastName
                    ? `${elt.firstName || ""} ${elt.lastName || ""}`
                    : elt.email,
                value: elt.id,
              })),
            };
          }
        }),
        columns: ["_id", "Nom", "Séance", "Pour", "Action"],
      };
    }
  };

  return (
    <>
      <div className="form-head d-flex mb-3 mb-lg-5 align-items-start filter gap-2">
        <div className="input-group search-area documents-seach-area ">
          <input
            type="text"
            className="form-control"
            placeholder="Rechercher ici ..."
            value={searchString}
            onChange={(e) => {
              searchDocuments(e.target.value);
            }}
          />
        </div>
        <Dropdown>
          <Dropdown.Toggle
            variant="primary"
            id="dropdown-basic"
            className="text-white"
          >
            <i className="flaticon-381-controls-3 "></i> {filterBy.label}
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-right">
            {filterOptions.map((elt, key) => {
              return (
                <Dropdown.Item
                  onClick={() => {
                    setSkip(false);
                    setSortObj(elt.value);
                    setFilterBy(elt);
                  }}
                  key={key}
                >
                  {elt.label}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <button
          className="btn btn-secondary"
          type="button"
          onClick={() => {
            setSortObj(initialFilterState.value);
            setFilterBy(initialFilterState);
            setSearchString("");
            setFilterObj({
              users: user._id,
            });
            setSkip(false);
          }}
        >
          Réinitialiser
        </button>
        <button
          className="btn btn-danger text-white"
          onClick={() => setAddFilesModal(true)}
        >
          Ajouter des fichiers
        </button>
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <div className=" spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {documents && documents.documents.length > 0 ? (
            <div className="card">
              <div className="card-body">
                <ProfileDatatable
                  data={tableDataFormatter()}
                  total={documents.totalPages}
                  setViewModal={setViewModal}
                  setDeleteModal={setDeleteModal}
                  setID={setDocumentID}
                  endpoint="documents"
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      <Modal className="modal fade" show={addFilesModal}>
        <div className="modal-content">
          <div className="modal-header text-center justify-content-center">
            <h5 className="modal-title">Ajouter des fichiers</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => closeFileUploadModal()}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body upload-file-modal">
            <div className="row">
              {user.role !== "PATIENT" && !isPatientsLoading && (
                <div className="col-12 input-group mb-3 file-users-select">
                  <div className="form-group col-12">
                    <label>Pour</label>
                    <Select
                      defaultValue={fileUsers}
                      onChange={(e) => setFileUsers(e)}
                      options={participantsOptionsCreator(user.role)}
                      isMulti
                      placeholder={""}
                      className="custom-multi-select"
                    />
                  </div>
                </div>
              )}

              <div className="col-12 input-group mb-3 file-users-select">
                <div className="form-group col-12">
                  <label>Séance</label>
                  <Select
                    defaultValue={fileSession}
                    onChange={(e) => setFileSession(e)}
                    options={sessionsOptions()}
                    placeholder={""}
                    className="custom-multi-select"
                  />
                </div>
              </div>
              <div className="col-12 input-group mb-3 basic-form custom_file_input custom-file-upload-group">
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      multiple
                      onChange={(e) => {
                        if (!e.target.files) {
                          return;
                        }

                        setFiles(Array.from(e.target.files));
                      }}
                    />
                    <label className="custom-file-label">
                      Télécharger un ou plusieurs fichiers
                    </label>
                  </div>
                </div>
              </div>
              {files.length > 0 && (
                <ul className="col-12 list-group file-list">
                  {files.map((elt, key) => (
                    <li className="list-group-item file-list-item" key={key}>
                      <div className="file-list-item-name">{elt.name}</div>

                      <div className="file-list-item-actions">
                        <i
                          className="fa fa-trash"
                          onClick={() => {
                            removeFileFromFileList(elt);
                          }}
                        ></i>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="d-flex my-3 justify-content-center">
              <button
                className="btn btn-primary text-white"
                onClick={async () => {
                  await uploadFiles();
                }}
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal className="modal fade" show={deleteModal}>
        <div className="modal-content">
          <div className="modal-header text-center justify-content-center">
            <h5 className="modal-title">Supprimer un document</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setDeleteModal(false)}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-center">
              <p> Êtes-vous sûr de vouloir supprimer ce document ?</p>
            </div>
            <div className="d-flex justify-content-center">
              <div className="form-group">
                {deleteDocumentIsLoading ? (
                  <div className="d-flex justify-content-center">
                    <div className=" spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <input
                    type="submit"
                    value="Supprimer"
                    className="submit btn btn-danger"
                    onClick={async () => {
                      try {
                        await deleteDocument({
                          _id: documentID,
                        }).unwrap();
                        setDeleteModal(false);
                      } catch (error) {
                        console.log("deleteDocument : ", error);
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <DocumentViewer
        fileType={
          getDocumentUrl(documentID)
            ? getDocumentUrl(documentID).split(".").pop()
            : null
        }
        url={getDocumentUrl(documentID)}
        setUrl={setDocumentID}
        viewModal={viewModal}
        setViewModal={setViewModal}
      />
    </>
  );
}

export default Documents;
