import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  resetCredentials,
  setCredentials,
} from "../../features/auth/authSlice";
import { persistor } from "../../store";
import { apiSlice } from "../../api/apiSlice";
import miniLogo from "../../images/LOGO_IMS_MINI.png";
import Footer from "../layouts/Footer";

const getFormSchema = (location) => {
  let obj = {
    password: yup.string().required("Mot de passe requis"),
    confirm: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        "Les mots de passe ne correspondent pas"
      ),
  };
  if (location.includes("reset-password")) {
    obj = {
      ...obj,
      email: yup.string().required("Email requis"),
    };
  }
  const formSchema = yup.object(obj).required();
  return formSchema;
};

const conf = config[process.env.NODE_ENV];

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getFormSchema(location.pathname)),
  });

  const resetPassword = async (data) => {
    try {
      const { data: resetData } = await axios.post(
        `${conf.API_URL}/auth/resetPassword`,
        {
          email: data.email,
          password: data.password,
        }
      );

      let path = "/";

      if (resetData.user && resetData.token) {
        toast.success("Mot de passe modifié!");
        let obj = {
          token: resetData.token,
        };
        if (!token) {
          obj = {
            ...obj,
            user: resetData.user,
          };
          if (
            resetData.user.role === "PATIENT" &&
            resetData.user.patient.subscriptions &&
            resetData.user.patient.subscriptions.length > 0
          ) {
            obj = {
              ...obj,
              subscriptions: resetData.user.patient.subscriptions,
            };
          }
        }
        dispatch(setCredentials(obj));
      } else {
        toast.warn(
          "Oups, un problème est survenu, veuillez réessayer plus tard."
        );
        persistor.purge();
        dispatch(apiSlice.util.resetApiState());
        dispatch(resetCredentials());
        path = "/login";
      }

      setTimeout(() => {
        navigate(path);
      }, 2000);
    } catch (error) {
      console.log("resetPassword : ", error);
    }
  };

  const setPassword = async (data) => {
    try {
      const { data: resetData } = await axios.post(
        `${conf.API_URL}/auth/setPassword`,
        {
          user_id: searchParams.get("user_id"),
          password: data.password,
        }
      );

      let path = "/";

      if (resetData.user && resetData.token) {
        toast.success("Mot de passe ajouté!");
        let obj = {
          token: resetData.token,
        };
        if (!token) {
          obj = {
            ...obj,
            user: resetData.user,
          };
          if (
            resetData.user.role === "PATIENT" &&
            resetData.user.patient.subscriptions &&
            resetData.user.patient.subscriptions.length > 0
          ) {
            obj = {
              ...obj,
              subscriptions: resetData.user.patient.subscriptions,
            };
          }
        }
        dispatch(setCredentials(obj));
      } else {
        toast.warn(
          "Oups, un problème est survenu, veuillez réessayer plus tard."
        );
        persistor.purge();
        dispatch(apiSlice.util.resetApiState());
        dispatch(resetCredentials());
        path = "/login";
      }

      setTimeout(() => {
        navigate(path);
      }, 2000);
    } catch (error) {
      console.log("resetPassword : ", error);
    }
  };

  const verifyResetToken = async (token, userID) => {
    try {
      const { data } = await axios.post(
        `${conf.API_URL}/auth/verifyResetPasswordToken`,
        {
          token: token,
          userID: userID,
        }
      );
      if (!data.valid) {
        navigate("/");
      }
    } catch (error) {
      console.log("editUser : ", error);
      navigate("/");
    }
  };

  const verifyCreationToken = async (token, userID) => {
    try {
      const { data } = await axios.post(
        `${conf.API_URL}/auth/verifyCreationToken`,
        {
          token: token,
          userID: userID,
        }
      );
      if (!data.valid) {
        navigate("/");
      }
    } catch (error) {
      console.log("verifyCreationToken : ", error);
      navigate("/");
    }
  };

  const logout = () => {
    persistor.purge();
    dispatch(apiSlice.util.resetApiState());
    dispatch(resetCredentials());
  };

  const submitHandler = async (data) => {
    if (location.pathname.includes("creation-validation")) {
      return await setPassword(data);
    }
    if (location.pathname.includes("reset-password")) {
      return await resetPassword(data);
    }
  };

  useEffect(() => {
    if (location.pathname.includes("creation-validation")) {
      if (searchParams.get("token") && searchParams.get("user_id")) {
        verifyCreationToken(
          searchParams.get("token"),
          searchParams.get("user_id")
        );
      } else {
        logout();
        navigate("/login");
      }
    }
    if (location.pathname.includes("reset-password")) {
      if (searchParams.get("token")) {
        verifyResetToken(searchParams.get("token"), searchParams.get("id"));
      } else {
        logout();
        navigate("/login");
      }
    }
  }, [location]);

  return (
    <>
      <div
        className={`authincation ${
          location.pathname.includes("creation-validation")
            ? "creation-validation"
            : "p-meddle "
        }`}
      >
        <div className="container">
          <div className="row justify-content-center h-100 align-items-center auth-card">
            <div className="col-md-6 auth-card-content">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12 auth-card-logo">
                    <img src={miniLogo} alt="" />
                  </div>
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <form onSubmit={handleSubmit(submitHandler)}>
                        <h4 className="text-center mb-4">
                          {location.pathname.includes("creation-validation") &&
                            "Tapez votre mot de passe"}
                          {location.pathname.includes("reset-password") &&
                            "Mot de passe oublié"}
                        </h4>
                        {location.pathname.includes("reset-password") && (
                          <div className="form-group">
                            <label>
                              <strong>Email</strong>
                            </label>
                            <div>
                              <input
                                type="email"
                                className="form-control"
                                {...register("email")}
                              />
                            </div>
                            <div>
                              <span className="text-red">
                                {errors.email?.message}
                              </span>
                            </div>
                          </div>
                        )}

                        <div className="form-group">
                          <label>
                            <strong>Mot de passe</strong>
                          </label>
                          <div className="input-group transparent-append">
                            <input
                              type={`${showPassword ? "text" : "password"}`}
                              className="form-control"
                              name="password"
                              {...register("password")}
                            />
                            <div
                              className="input-group-append"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <span className="input-group-text input-icon-right">
                                {showPassword ? (
                                  <i className="fa-solid fa-eye"></i>
                                ) : (
                                  <i className="fa-solid fa-eye-slash"></i>
                                )}
                              </span>
                            </div>
                          </div>
                          <div>
                            <p className="text-red">
                              {errors.password?.message}
                            </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label>
                            <strong>Retapez le mot de passe</strong>
                          </label>
                          <div className="input-group transparent-append">
                            <input
                              type={`${showConfirm ? "text" : "password"}`}
                              className="form-control"
                              name="password"
                              {...register("confirm")}
                            />
                            <div
                              className="input-group-append"
                              onClick={() => setShowConfirm(!showConfirm)}
                            >
                              <span className="input-group-text input-icon-right">
                                {showConfirm ? (
                                  <i className="fa-solid fa-eye"></i>
                                ) : (
                                  <i className="fa-solid fa-eye-slash"></i>
                                )}
                              </span>
                            </div>
                          </div>
                          <div>
                            <span className="text-red">
                              {errors.confirm?.message}
                            </span>
                          </div>
                        </div>
                        <div className="text-center">
                          <input
                            type="submit"
                            value="Enregistrer"
                            className="btn btn-primary btn-block text-white"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {location.pathname.includes("creation-validation") && (
        <Footer isAuthFooter />
      )}
    </>
  );
};

export default ResetPassword;
