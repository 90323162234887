import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import { useGetPayementCheckoutSessionQuery } from "../../../features/packs/packsAPISlice";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_PULIC_KEY);

const Checkout = () => {
  const { packId } = useParams();

  const { data: payementSessionData, isLoading: isPayementSessionDataLoading } =
    useGetPayementCheckoutSessionQuery(packId);

  return (
    <div className="card m-3">
      <div className="card-body">
        {!isPayementSessionDataLoading &&
          payementSessionData &&
          payementSessionData.session &&
          payementSessionData.session.client_secret && (
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{
                clientSecret: payementSessionData.session.client_secret,
              }}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          )}
      </div>
    </div>
  );
};

export default Checkout;
