import React, { useEffect, useRef, useState } from "react";
import avatarEmpty from "../../../images/avatar_empty.png";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { socket } from "../../../helpers/socket";
import Select from "react-select";
import { Button, Modal } from "react-bootstrap";
import {
  useCreateMessageMutation,
  useCreateRoomMutation,
  useFilterRoomsMutation,
  useGetCurrentRoomQuery,
  useGetRoomsByParticipantsQuery,
} from "../../../features/messages/messagesAPISlice";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch } from "react-redux";
import { apiSlice } from "../../../api/apiSlice";
import { useGetAllPatientsQuery } from "../../../features/patients/patientsAPISlice";
import DocumentViewer from "../../components/DocumentViewer";

const Message = () => {
  const [user] = useOutletContext();
  const [message, setMessage] = useState("");
  const [skip, setSkip] = useState(true);
  const [messageReciever, setMessageReciever] = useState(null);
  const [sendMessageModal, setSendMessageModal] = useState(false);
  const [file, setFile] = useState(null);
  const [messageFile, setMessageFile] = useState(null);
  const [fileToView, setFileToView] = useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: patients, isLoading: patientsIsLoading } =
    useGetAllPatientsQuery(0, 0);
  const { data: rooms, isRoomsByParticipantsLoading } =
    useGetRoomsByParticipantsQuery({
      participants: [user.id],
    });

  const inputRef = useRef(null);
  const messageInputRef = useRef(null);
  const [currentRoom, setCurrentRoom] = useState(
    searchParams.get("conversation") || null
  );
  const { data: currentRoomData, isLoading: currentRoomDataIsLoading } =
    useGetCurrentRoomQuery(currentRoom, {
      skip,
    });

  const [createMessage] = useCreateMessageMutation();
  const [createRoom] = useCreateRoomMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    socket.connect();
    dispatch(apiSlice.util.invalidateTags(["ParticipantsRooms"]));
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (currentRoom) {
      socket.on(`message-received-${currentRoom}`, (receivedData) => {
        if (receivedData && receivedData.receiver === user._id) {
          dispatch(
            apiSlice.util.invalidateTags(["CurrentRoom", "ParticipantsRooms"])
          );
        }
      });
    }
  }, [currentRoom]);

  const updateCurrentRoom = (roomId) => {
    setCurrentRoom(roomId);
    setSearchParams((searchParams) => {
      searchParams.set("conversation", roomId);
      return searchParams;
    });
    setMessage("");
    setFile(null);
  };

  useEffect(() => {
    if (rooms && rooms.rooms.length > 0) {
      updateCurrentRoom(
        searchParams.get("conversation")
          ? searchParams.get("conversation")
          : rooms.rooms[0]._id
      );
      setSkip(false);
    }
  }, [rooms]);

  const createRoomFct = async (receiversArray = []) => {
    try {
      let participantsArr = [user.id];
      if (user.role === "PATIENT" && user.patient && user.patient.doctor) {
        participantsArr = [...participantsArr, user.patient.doctor._id];
      }

      if (receiversArray.length > 0) {
        participantsArr = [...participantsArr, ...receiversArray];
      }

      participantsArr = [...new Set(participantsArr)];

      const room = await createRoom({
        participants: participantsArr,
      }).unwrap();

      if (room && room.room && room.room._id) {
        updateCurrentRoom(room.room._id);
        setSkip(false);
        return room.room._id;
      }
      return null;
    } catch (error) {
      console.log("sendMessage : ", error);
    }
  };

  const sendMessage = async (
    messageText,
    sentFile,
    chatRoommId,
    receiversArray = []
  ) => {
    try {
      if (!messageText && !sentFile) {
        toast.warn("Veuillez envoyer un texte ou un fichier");
        return;
      }

      if (user.role === "PATIENT" && user.patient && !user.patient.doctor) {
        toast.warn(
          "Vous n'êtes pas lié à un psychologue, veuillez contacter notre administrateur pour ajouter le lien."
        );
        return;
      }

      let chatRoomm = chatRoommId;

      let formData = new FormData();

      formData.append("sender", user._id);

      if (messageText) {
        formData.append("text", messageText);
      }

      if (sentFile) {
        formData.append("file", sentFile, `V${Date.now()}-${sentFile.name}`);
      }

      let receivers = [];
      if (!chatRoomm) {
        const roomId = await createRoomFct(receiversArray);
        formData.append("room", roomId);
        if (user.role === "PATIENT" && user.patient && user.patient.doctor) {
          receivers = [user.patient.doctor._id];
        }
      } else {
        formData.append("room", chatRoomm);
        receivers = currentRoomData.room.participants.map((elt) => elt._id);
      }

      if (receiversArray.length > 0) {
        receivers = receiversArray;
      }

      const messageData = await createMessage(formData).unwrap();
      if (messageData && messageData.message && messageData.message._id) {
        socket.emit("message-created", {
          room: messageData.message.room,
          receivers: receivers,
        });
        setSkip(false);
      }

      setMessage("");
      setFile(null);
      if (sendMessageModal) {
        closeMessageSendModal();
      }
    } catch (error) {
      console.log("sendMessage : ", error);
    }
  };

  const getSenderInfo = (participantsArray, messagesArray) => {
    let lastMessage = messagesArray[messagesArray.length - 1];
    let data = null;
    for (let participant of participantsArray) {
      if (lastMessage && lastMessage.sender === participant._id) {
        data = {
          photo: participant.photo || avatarEmpty,
          ...participant,
        };
      }
    }

    return data;
  };

  const closeMessageSendModal = () => {
    setMessageReciever(null);
    setSendMessageModal(false);
    setMessageFile(null);
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      e.target.value = null;
    }
  };

  const handleMessageFileChange = (e) => {
    if (e.target.files) {
      setMessageFile(e.target.files[0]);
      e.target.value = null;
    }
  };

  const handleClick = () => {
    inputRef.current.click();
  };
  const handleMessageClick = () => {
    messageInputRef.current.click();
  };

  return (
    <>
      <div className="row chat">
        <div className={`col-md-4 chat-list`}>
          <div className="chat-list-settings">
            <p>Mes messages</p>
            {(user.role === "DOCTOR" ||
              (user.role === "PATIENT" &&
                user.patient &&
                user.patient.doctor)) && (
              <i
                className="fa-solid fa-pen-to-square"
                onClick={() => setSendMessageModal(true)}
              ></i>
            )}
          </div>
          {isRoomsByParticipantsLoading ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <>
              {rooms && rooms.rooms.length > 0 ? (
                rooms.rooms.map((elt, key) => {
                  return (
                    <div
                      key={key}
                      className={`chat-list-conversation ${
                        searchParams.get("conversation") &&
                        searchParams.get("conversation") === elt._id
                          ? "--active"
                          : ""
                      } ${key === 0 ? "--first" : ""}`}
                      onClick={() => {
                        updateCurrentRoom(elt._id);
                      }}
                    >
                      <div className="chat-list-conversation-title">
                        <h4 className="chat-list-conversation-title-text">
                          {elt.title}
                        </h4>
                      </div>
                      {elt.messages && elt.messages.length > 0 && (
                        <>
                          {/* <div className="chat-list-conversation-img">
                            <img
                              src={getSenderInfo(elt.participants, elt.messages).photo}
                              className="chat-list-conversation-img-photo"
                              alt=""
                            />
                          </div> */}
                          <div className="chat-list-conversation-msg">
                            <p className="chat-list-conversation-msg-text">
                              {getSenderInfo(elt.participants, elt.messages)
                                ? `${
                                    getSenderInfo(
                                      elt.participants,
                                      elt.messages
                                    ).firstName
                                  } : `
                                : ""}
                              {elt.messages[elt.messages.length - 1].text}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        <div className={`col-md-8 chat-container`}>
          <div className="chat-container-messages">
            {!currentRoomDataIsLoading &&
              currentRoomData &&
              currentRoomData.room &&
              currentRoomData.room.messages &&
              currentRoomData.room.messages.length > 0 &&
              currentRoomData.room.messages.map((msg, key) => {
                return (
                  <div
                    className={`d-flex justify-content-${
                      msg.sender._id === user._id ? "start" : "start"
                    } mb-4 chat-line --${
                      msg.sender._id === user._id ? "me" : "other"
                    }`}
                    key={key}
                  >
                    <div className="chat-line-img">
                      <img
                        src={msg.sender.photo || avatarEmpty}
                        className="chat-line-img-photo"
                        alt=""
                      />
                    </div>
                    <div
                      className={`chat-line-msg --${
                        msg.sender._id === user._id ? "end" : "start"
                      }`}
                    >
                      {msg.text && (
                        <p className="chat-line-msg-text">{msg.text}</p>
                      )}

                      {msg.file && (
                        <p
                          className="chat-line-msg-file"
                          onClick={() => {
                            setFileToView(msg.file);
                            setViewModal(true);
                          }}
                        >
                          <i className="fa-solid fa-file"></i>{" "}
                          {msg.file.substring(msg.file.lastIndexOf("/") + 1)}
                        </p>
                      )}

                      <p className="chat-line-msg-time">
                        {moment(msg.createdAt).format("DD - MM - YYYY HH:mm")}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>

          <div className={`chat-container-input row`}>
            <div className="col-md-8 chat-container-input-text">
              <input
                className="form-control"
                placeholder="Envoyez un message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                type="text"
              />
            </div>

            <div className="col-md-4 chat-container-input-actions">
              <input
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
                ref={inputRef}
              ></input>
              <button
                type="button"
                className="btn btn-secondary chat-input-actions-send"
                onClick={handleClick}
              >
                <i className="fa-solid fa-paperclip"></i>
              </button>

              <button
                type="button"
                className="btn btn-primary chat-input-actions-send"
                onClick={async () => sendMessage(message, file, currentRoom)}
                disabled={
                  user.role === "ADMIN" ||
                  (user.role === "DOCTOR" && rooms && rooms.rooms.length === 0)
                }
              >
                <i className="fa fa-location-arrow"></i>
              </button>
            </div>
          </div>

          {file && file.name && (
            <div className={`chat-container-file`}>
              <button
                type="button"
                className="btn btn-danger chat-container-file-send"
              >
                <i
                  className="fa-solid fa-circle-xmark"
                  onClick={() => {
                    setFile(null);
                  }}
                ></i>

                {file.name}
              </button>
            </div>
          )}
        </div>
      </div>

      <Modal className="modal fade" show={sendMessageModal}>
        <div className="modal-content">
          <div className="modal-header text-center justify-content-center">
            <h5 className="modal-title">Envoyer un message</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => closeMessageSendModal()}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body upload-file-modal">
            <div className="row">
              {user.role === "DOCTOR" && (
                <div className="col-12 input-group mb-3 file-users-select">
                  {!patientsIsLoading && (
                    <div className="form-group col-12">
                      <label>Patient</label>
                      <Select
                        defaultValue={messageReciever}
                        onChange={(e) => setMessageReciever(e)}
                        options={[
                          ...patients.users.map((elt) => ({
                            label:
                              elt.firstName || elt.lastName
                                ? `${elt.firstName || ""} ${elt.lastName || ""}`
                                : elt.email,
                            value: elt.id,
                          })),
                        ]}
                        placeholder={""}
                        className="custom-multi-select"
                      />
                    </div>
                  )}
                </div>
              )}

              <div className="col-12 input-group mb-3 basic-form custom_file_input custom-file-upload-group  row">
                <div className="input-group col-md-10">
                  <textarea
                    className="form-control"
                    placeholder="Envoyez un message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <div className="col-md-2 chat-container-input-actions">
                  <input
                    type="file"
                    onChange={handleMessageFileChange}
                    style={{ display: "none" }}
                    ref={messageInputRef}
                  ></input>
                  <button
                    type="button"
                    className="btn btn-secondary chat-input-actions-send"
                    onClick={handleMessageClick}
                  >
                    <i className="fa-solid fa-paperclip"></i>
                  </button>
                </div>
              </div>
              <div className="col-12 input-group mb-3 basic-form custom_file_input custom-file-upload-group">
                {messageFile && messageFile.name && (
                  <div className={`chat-container-file  w-100`}>
                    <button
                      type="button"
                      className="btn btn-danger chat-container-file-send  w-100"
                    >
                      <i
                        className="fa-solid fa-circle-xmark"
                        onClick={() => {
                          setMessageFile(null);
                        }}
                      ></i>

                      {messageFile.name}
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex my-3 justify-content-center">
              <button
                className="btn btn-primary text-white"
                onClick={async () => {
                  if (!messageReciever && user.role === "DOCTOR") {
                    toast.warn("Veuillez sélectionner un destinataire");
                    return;
                  }
                  await sendMessage(
                    message,
                    messageFile,
                    null,
                    user.role === "PATIENT" &&
                      user.patient &&
                      user.patient.doctor &&
                      user.patient.doctor._id
                      ? [user.patient.doctor._id]
                      : [messageReciever.value]
                  );
                }}
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <DocumentViewer
        fileType={fileToView ? fileToView.split(".").pop() : null}
        url={fileToView}
        setUrl={setFileToView}
        viewModal={viewModal}
        setViewModal={setViewModal}
      />
    </>
  );
};

export default Message;
