import React, { Fragment, useEffect, useState } from "react";
//** Import Image */
import avatarEmpty from "../../../images/avatar_empty.png";

import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import BasicDatePicker from "../../components/Forms/Pickers/MetarialDate";
import { useAddDoctorMutation } from "../../../features/doctors/doctorsAPISlice";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
// import { SRLWrapper } from "simple-react-lightbox";

const DoctorAdd = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm();
  const [addDoctor, { addDoctorIsLoading }] = useAddDoctorMutation();
  const navigate = useNavigate();

  const [birthDate, setBirthDate] = useState(null);
  const [profilePhto, setProfilePhoto] = useState(avatarEmpty);

  const onSubmit = async (data) => {
    try {
      let obj = {};

      if (data.firstName && dirtyFields["firstName"]) {
        obj = {
          ...obj,
          firstName: data.firstName,
        };
      }
      if (data.lastName && dirtyFields["lastName"]) {
        obj = {
          ...obj,
          lastName: data.lastName,
        };
      }
      if (data.email && dirtyFields["email"]) {
        obj = {
          ...obj,
          email: data.email,
        };
      }
      if (data.phone && dirtyFields["phone"]) {
        obj = {
          ...obj,
          phone: data.phone,
        };
      }
      if (data.gender && data.gender.value) {
        obj = {
          ...obj,
          gender: data.gender.value,
        };
      }
      if (profilePhto && typeof profilePhto !== "string") {
        obj = {
          ...obj,
          photo: profilePhto,
        };
      }
      if (data.diploma && dirtyFields["diploma"]) {
        obj = {
          ...obj,
          diploma: data.diploma,
        };
      }
      if (data.graduationYear && dirtyFields["graduationYear"]) {
        obj = {
          ...obj,
          graduationYear: data.graduationYear,
        };
      }
      if (Object.values(obj).length > 1) {
        const userData = await addDoctor(obj).unwrap();
        if (userData.user && userData.user.user && userData.user.user._id) {
          navigate(`/psychologues/details/${userData.user.user._id}`);
        }
      }
    } catch (error) {
      console.log("onSubmit : ", error);
    }
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <div className="tab-content">
                    <div
                      id="profile-info"
                      className={`tab-pane fade active show`}
                    >
                      <div className="pt-3">
                        <div className="settings-form">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-row">
                              <div className="form-group col-md-4 author-wrapper">
                                <div className="author-profile">
                                  <div className="author-media">
                                    <img
                                      src={
                                        typeof profilePhto !== "string"
                                          ? URL.createObjectURL(profilePhto)
                                          : profilePhto
                                      }
                                      alt=""
                                    />
                                    <div
                                      className="upload-link"
                                      title=""
                                      data-toggle="tooltip"
                                      data-placement="right"
                                      data-original-title="update"
                                      onChange={(e) => {
                                        const files = e.target.files;
                                        setProfilePhoto(files[0]);
                                      }}
                                    >
                                      <input
                                        type="file"
                                        className="update-flie"
                                      />
                                      <i className="fa fa-camera"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-8 row">
                                <div className="form-group  col-md-6">
                                  <label>Nom</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    {...register("lastName")}
                                  />
                                </div>
                                <div className="form-group  col-md-6">
                                  <label>Prénom</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    {...register("firstName")}
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Email</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    {...register("email")}
                                    required
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Téléphone</label>
                                  <input
                                    type="phone"
                                    className="form-control"
                                    {...register("phone")}
                                    required
                                  />
                                </div>
                                <div className="form-group  col-md-6">
                                  <label>Genre</label>
                                  <Controller
                                    name="gender"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        options={[
                                          { label: "Homme", value: "Homme" },
                                          { label: "Femme", value: "Femme" },
                                          { label: "Autre", value: "Autre" },
                                        ]}
                                        closeMenuOnSelect={true}
                                        placeholder={""}
                                        classNamePrefix="cutsom_select"
                                      />
                                    )}
                                  />
                                </div>
                                <div className="form-group  col-md-6">
                                  <label>Diplôme</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    {...register("diploma")}
                                  />
                                </div>
                                <div className="form-group  col-md-6">
                                  <label>Année d'obtention du diplôme</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    {...register("graduationYear")}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row justify-content-end">
                              {addDoctorIsLoading ? (
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <button
                                  className="btn btn-primary text-right  text-white"
                                  type="submit"
                                >
                                  Enregistrer
                                </button>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DoctorAdd;
