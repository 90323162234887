import React, { Fragment, useContext, useState } from "react";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import ChatBox from "../ChatBox";
import { ThemeContext } from "../../../context/ThemeContext";

const JobieNav = () => {
  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  const {
    navigationHader,
    openMenuToggle,
    background,
    changeBackground,
    sidebarOpen,
    setSideBarOpen,
  } = useContext(ThemeContext);
  return (
    <Fragment>
      <div className={`${sidebarOpen ? "" : "menu-toggle"}`}>
        <NavHader
          sidebarOpen={sidebarOpen}
          setSideBarOpen={setSideBarOpen}
          navigationHader={navigationHader}
          openMenuToggle={openMenuToggle}
          background={background}
        />
        <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} />
        <Header changeBackground={changeBackground} background={background} />
        <SideBar />
      </div>
    </Fragment>
  );
};

export default JobieNav;
