import React from "react";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <div className="mid-page">
      <div className="authincation h-100 p-meddle">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center ">
            <div className="col-md-5">
              <div className="form-input-content text-center error-page">
                <h4>
                  <i className="fa fa-exclamation-triangle text-warning" /> La
                  page que vous recherchez est introuvable !
                </h4>
                <p>
                  Il se peut que vous ayez mal saisi l'adresse ou que la page
                  ait été déplacée.
                </p>
                <div>
                  <Link className="btn btn-primary" to="/">
                    Retour à l'accueil
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
