import moment from "moment";
import { apiSlice } from "../../api/apiSlice";

export const doctorsAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllDoctors: builder.query({
      query: (page = 1, limit = 10) => ({
        url: `/doctors/all?page=${page}&limit=${limit}`,
      }),
      providesTags: ["Doctors"],
    }),
    getDoctorById: builder.query({
      query: (id) => {
        return {
          url: `/doctors/${id}`,
        };
      },
      providesTags: ["SingleDoctor"],
    }),
    updateDoctorInfo: builder.mutation({
      query: (credentials) => {
        let { _id, ...rest } = credentials;
        let payload = rest;
        const formData = new FormData();
        if (rest.photo && typeof rest.photo !== "string") {
          for (let key of Object.keys(rest)) {
            formData.append(key, rest[key]);
          }
          payload = formData;
        }
        return {
          url: `/doctors/${_id}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["Doctors", "SingleDoctor", "User"],
    }),
    addDoctor: builder.mutation({
      query: (credentials) => {
        let payload = credentials;
        const formData = new FormData();
        if (credentials.photo && typeof credentials.photo !== "string") {
          for (let key of Object.keys(credentials)) {
            formData.append(key, credentials[key]);
          }
          payload = formData;
        }
        return {
          url: `/doctors/create`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Doctors"],
    }),
    deleteDoctor: builder.mutation({
      query: (credentials) => {
        return {
          url: `/doctors/${credentials._id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Doctors"],
    }),
    getDoctorSessionsByDate: builder.query({
      query: ({
        id,
        startDate = moment().startOf("month").format(),
        endDate = moment().endOf("month").format(),
        exclude,
      }) => {
        let obj = {
          url: `/sessions/filter?page=${0}&limit=${0}`,
          method: "POST",
          body: {
            filter: {
              $and: [
                { participants: { $ne: exclude } },
                { participants: { $eq: id } },
              ],
              startDate: { $gte: startDate },
              endDate: { $lte: endDate },
            },
          },
        };

        return obj;
      },
      providesTags: ["DoctorSessions"],
    }),
    getDoctorRdvsByDate: builder.query({
      query: ({
        role,
        id,
        startDate = moment().startOf("month").format(),
        endDate = moment().endOf("month").format(),
      }) => {
        let obj = {
          url: `/calendarEvents/filter?page=${0}&limit=${0}`,
          method: "POST",
          body: {
            filter: {
              participants: id,
              startDate: { $gte: startDate },
              endDate: { $lte: endDate },
            },
          },
        };

        if (role == "DOCTOR" || role === "PATIENT") {
          obj = {
            url: `/sessions/filter?page=${0}&limit=${0}`,
            method: "POST",
            body: {
              filter: {
                participants: id,
                startDate: { $gte: startDate },
                endDate: { $lte: endDate },
              },
            },
          };
        }

        return obj;
      },
      providesTags: ["Sessions"],
    }),
  }),
});

export const {
  useGetAllDoctorsQuery,
  useGetDoctorSessionsByDateQuery,
  useGetDoctorByIdQuery,
  useUpdateDoctorInfoMutation,
  useAddDoctorMutation,
  useDeleteDoctorMutation,
} = doctorsAPISlice;
