import React from "react";
/// Layout
import Footer from "./Footer";
import { Navigate } from "react-router-dom";

export default function AuthLayout({ component: Component }) {
  if (
    localStorage.getItem("persist:creds") !== null &&
    JSON.parse(localStorage.getItem("persist:creds")).token &&
    JSON.parse(localStorage.getItem("persist:creds")).token !== "null" &&
    JSON.parse(localStorage.getItem("persist:creds")).token !== null
  ) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div id="main-wrapper" className="show" style={{ minHeight: "100vh" }}>
        {Component}
      </div>
      <Footer isAuthFooter />
    </>
  );
}
