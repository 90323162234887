import React, { Fragment, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useGetAllPatientsQuery } from "../../../features/patients/patientsAPISlice";
import DateTimePicker from "../../components/Forms/Pickers/MetarialDateAndTime";

import moment from "moment";
import Select from "react-select";
import {
  useGetGroupByIdQuery,
  useUpdateGroupInfoMutation,
} from "../../../features/groups/groupsAPISlice";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  useGetAllDoctorsQuery,
  useValidateDoctorAvailabilityMutation,
} from "../../../features/doctors/doctorsAPISlice";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import {
  useAddDocumentMutation,
  useDeleteDocumentMutation,
  useGetDocumentByGroupIdQuery,
} from "../../../features/documents/documentsAPISlice";
import ProfileDatatable from "../../components/table/ProfileDatatable";
import DocumentViewer from "../../components/DocumentViewer";

// import { SRLWrapper } from "simple-react-lightbox";

const GroupDetails = () => {
  const [user] = useOutletContext();
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { dirtyFields },
  } = useForm();
  const { id } = useParams();
  const { data: groupData } = useGetGroupByIdQuery(id);
  const { data: doctors, isLoading: doctorsIsLoading } = useGetAllDoctorsQuery(
    0,
    0
  );
  const { data: patients, isLoading: patientsIsLoading } =
    useGetAllPatientsQuery(0, 0);

  const [updateGroupInfo, { updateGroupInfoIsLoading }] =
    useUpdateGroupInfoMutation();

  const [participantsLoading, setParticipantsLoading] = useState(true);
  const [dates, setDates] = useState({
    date_1: null,
    date_2: null,
    date_3: null,
    date_4: null,
    date_5: null,
    date_6: null,
  });

  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    if (groupData) {
      let { group } = groupData;
      let obj = {
        title: group.title,
        description: group.description,
        limit: group.limit || 0,
        type: group.type || null,
      };

      if (group.date_1) {
        handleDates(group.date_1, "date_1");
      }
      if (group.date_2) {
        handleDates(group.date_2, "date_2");
      }
      if (group.date_3) {
        handleDates(group.date_3, "date_3");
      }
      if (group.date_4) {
        handleDates(group.date_4, "date_4");
      }
      if (group.date_5) {
        handleDates(group.date_5, "date_5");
      }
      if (group.date_6) {
        handleDates(group.date_6, "date_6");
      }

      if (group.participants && group.participants.length > 0) {
        let _participants = group.participants.map((elt) => ({
          label:
            elt.firstName || elt.lastName
              ? `${elt.firstName || ""} ${elt.lastName || ""}`
              : elt.email,
          value: elt.id,
        }));
        setParticipants(_participants);
      }
      reset(obj);
    }
  }, [groupData]);

  const participantsLoader = () => {
    return doctorsIsLoading && patientsIsLoading;
  };

  const participantsOptionsCreator = () => {
    return [
      ...patients.users.map((elt) => ({
        label:
          elt.firstName || elt.lastName
            ? `${elt.firstName || ""} ${elt.lastName || ""}`
            : elt.email,
        value: elt.id,
      })),
      ...doctors.users.map((elt) => ({
        label:
          elt.firstName || elt.lastName
            ? `${elt.firstName || ""} ${elt.lastName || ""}`
            : elt.email,
        value: elt.id,
      })),
    ];
  };

  const handleDates = (date, key) => {
    setDates((prevState) => {
      return {
        ...prevState,
        [key]: date,
      };
    });
  };

  useEffect(() => {
    const val = participantsLoader();
    setParticipantsLoading(val);
  }, [user]);

  const onSubmit = async (data) => {
    try {
      let obj = {
        _id: id,
      };

      let datesCheck = Object.values(dates);

      if (datesCheck.some((a) => typeof a === "undefined" || a === null)) {
        toast.error("Les 6 dates doivent être remplies!");
        return;
      }

      obj = {
        ...dates,
        ...obj,
      };

      if (data.title && dirtyFields["title"]) {
        obj = {
          ...obj,
          title: data.title,
        };
      }
      if (data.description && dirtyFields["description"]) {
        obj = {
          ...obj,
          description: data.description,
        };
      }
      if (data.limit && dirtyFields["limit"]) {
        obj = {
          ...obj,
          limit: data.limit,
        };
      }
      if (data.limit && dirtyFields["type"]) {
        obj = {
          ...obj,
          type: data.type,
        };
      }

      if (participants && participants.length > 0) {
        let _participants = participants.reduce(
          (acc, curr) => [...acc, curr["value"]],
          []
        );

        obj = {
          ...obj,
          participants: _participants,
        };
      }

      if (Object.values(obj).length > 1) {
        const groupData = await updateGroupInfo(obj).unwrap();
      }
    } catch (error) {
      console.log("onSubmit : ", error);
    }
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <div className="tab-content">
                    <div
                      id="profile-info"
                      className={`tab-pane fade active show`}
                    >
                      <div className="pt-3">
                        <div className="settings-form">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-row">
                              <div className="form-group col-md-12 row">
                                <div className="form-group col-md-6">
                                  <label>Titre</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    {...register("title")}
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Date 1</label>
                                  <DateTimePicker
                                    selectedDate={dates.date_1}
                                    setSelectedDate={(e) =>
                                      handleDates(e, "date_1")
                                    }
                                    className="form-control cutom-date-picker"
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Date 2</label>
                                  <DateTimePicker
                                    selectedDate={dates.date_2}
                                    setSelectedDate={(e) =>
                                      handleDates(e, "date_2")
                                    }
                                    className="form-control cutom-date-picker"
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Date 3</label>
                                  <DateTimePicker
                                    selectedDate={dates.date_3}
                                    setSelectedDate={(e) =>
                                      handleDates(e, "date_3")
                                    }
                                    className="form-control cutom-date-picker"
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Date 4</label>
                                  <DateTimePicker
                                    selectedDate={dates.date_4}
                                    setSelectedDate={(e) =>
                                      handleDates(e, "date_4")
                                    }
                                    className="form-control cutom-date-picker"
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Date 5</label>
                                  <DateTimePicker
                                    selectedDate={dates.date_5}
                                    setSelectedDate={(e) =>
                                      handleDates(e, "date_5")
                                    }
                                    className="form-control cutom-date-picker"
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Date 6</label>
                                  <DateTimePicker
                                    selectedDate={dates.date_6}
                                    setSelectedDate={(e) =>
                                      handleDates(e, "date_6")
                                    }
                                    className="form-control cutom-date-picker"
                                  />
                                </div>

                                {!participantsLoading && (
                                  <div className="form-group col-md-6">
                                    <label>Participants</label>
                                    <Select
                                      value={participants}
                                      onChange={(e) => setParticipants(e)}
                                      options={participantsOptionsCreator()}
                                      isMulti
                                      placeholder={""}
                                      className="custom-multi-select"
                                    />
                                  </div>
                                )}

                                {user.role === "ADMIN" && (
                                  <div className="form-group col-md-6">
                                    <label>Type</label>
                                    <select
                                      className="form-control"
                                      {...register("type")}
                                    >
                                      <option value="Visio_Grouped">
                                        En Visio
                                      </option>
                                      <option value="OnSite_Grouped">
                                        Sur place
                                      </option>
                                    </select>
                                  </div>
                                )}

                                <div className="form-group col-md-6">
                                  <label>Limite</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    min={0}
                                    step={1}
                                    {...register("limit")}
                                  />
                                </div>

                                <div className="form-group  col-md-12">
                                  <label>Description</label>
                                  <textarea
                                    rows="3"
                                    cols="50"
                                    className="form-control"
                                    {...register("description")}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row justify-content-end">
                              {updateGroupInfoIsLoading ? (
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <button
                                  className="btn btn-primary text-right text-white"
                                  type="submit"
                                >
                                  Enregistrer
                                </button>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GroupDetails;
