export default {
  development: {
    API_URL: "http://localhost:5000/api",
    BaseURL: "http://localhost:8000",
    SOCKET:"http://localhost:5000"
  },
  production: {
    API_URL: "https://api.institut.schoolcom.net:7874/api",
    BaseURL: "https://institut.schoolcom.net",
    SOCKET:"https://api.institut.schoolcom.net:7874"
  },
};
