import { apiSlice } from "../../api/apiSlice";
import moment from "moment";

export const sessionsAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSessions: builder.query({
      query: ({ role, id, page = 1, limit = 10 }) => {
        let obj = {
          url: `/sessions/all?page=${page}&limit=${limit}`,
        };
        switch (role) {
          case "DOCTOR":
            obj = {
              url: `/sessions/filter?page=${page}&limit=${limit}`,
              method: "POST",
              body: {
                filter: {
                  participants: id,
                },
              },
            };
            break;

          case "PATIENT":
            obj = {
              url: `/sessions/filter?page=${page}&limit=${limit}`,
              method: "POST",
              body: {
                filter: {
                  participants: id,
                },
              },
            };
            break;

          default:
            break;
        }
        return obj;
      },
      providesTags: ["Sessions"],
    }),
    getSessionsByDate: builder.query({
      query: ({
        role,
        id,
        startDate = moment().startOf("month").format(),
        endDate = moment().endOf("month").format(),
        limit = 0,
        page = 0,
      }) => {
        let obj = {
          url: `/sessions/filter?page=${page}&limit=${limit}`,
          method: "POST",
          body: {
            filter: {
              startDate: { $gte: startDate },
              endDate: { $lte: endDate },
            },
          },
        };

        if (role == "DOCTOR" || role === "PATIENT") {
          obj = {
            url: `/sessions/filter?page=${page}&limit=${limit}`,
            method: "POST",
            body: {
              filter: {
                participants: id,
                startDate: { $gte: startDate },
                endDate: { $lte: endDate },
              },
            },
          };
        }

        return obj;
      },
      providesTags: ["Sessions"],
    }),
    getSessionById: builder.query({
      query: (id) => {
        return {
          url: `/sessions/${id}`,
        };
      },
      providesTags: ["SingleSession"],
    }),
    updateSessionInfo: builder.mutation({
      query: (credentials) => {
        let { _id, ...rest } = credentials;
        let payload = rest;
        const formData = new FormData();
        if (rest.documents && typeof rest.documents !== "string") {
          let { documents, ..._rest } = rest;
          for (let key of Object.keys(_rest)) {
            formData.append(key, rest[key]);
          }
          for (let key of Object.keys(documents)) {
            formData.append(key, rest[key]);
          }
          payload = formData;
        }
        return {
          url: `/sessions/${_id}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["Sessions"],
    }),
    addSession: builder.mutation({
      query: (credentials) => {
        let payload = credentials;
        return {
          url: `/sessions/create`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Sessions"],
    }),
    deleteSession: builder.mutation({
      query: (credentials) => {
        return {
          url: `/sessions/${credentials._id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Sessions"],
    }),
    subscribeToSession: builder.mutation({
      query: (credentials) => {
        let payload = credentials;
        return {
          url: `/sessions/subscribeToSession`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Sessions", "User"],
    }),
    unSubscribeToSession: builder.mutation({
      query: (credentials) => {
        let payload = credentials;
        return {
          url: `/sessions/unSubscribeToSession`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Sessions", "User"],
    }),
  }),
});

export const {
  useGetAllSessionsQuery,
  useGetSessionByIdQuery,
  useUpdateSessionInfoMutation,
  useAddSessionMutation,
  useDeleteSessionMutation,
  useSubscribeToSessionMutation,
  useUnSubscribeToSessionMutation,
  useGetSessionsByDateQuery,
} = sessionsAPISlice;
