import React from "react";
import { Link } from "react-router-dom";

const ActionNotAuthorized = () => {
  return (
    <div className="mid-page">
      <div className="authincation h-100 p-meddle">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="form-input-content text-center error-page">
                <h4>Action non autorisée !</h4>
                <div>
                  <Link className="btn btn-primary" to="/">
                    Retour à l'accueil
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionNotAuthorized;
