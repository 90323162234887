import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";

import { Link, useLocation, useOutletContext } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/profile/17.jpg";
import avatarEmpty from "../../../images/avatar_empty.png";
import avatar from "../../../images/avatar/1.jpg";
import { set } from "date-fns/esm";
import { DetailsMenu, menuFinder } from "../../../helpers/menus";
import {
  resetCredentials,
  setCredentials,
} from "../../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { persistor } from "../../../store";
import { useUpdateMutation } from "../../../features/auth/authAPISlice";
import { apiSlice } from "../../../api/apiSlice";

const Header = ({ changeBackground, background }) => {
  const [fullScreen, setFullScreen] = useState(false);
  const [user, _, subscriptions] = useOutletContext();
  const [update, { isLoading }] = useUpdateMutation();
  const location = useLocation();
  let [pageName, setPageName] = useState("");
  const onFullScreen = () => {
    var elem = document.documentElement;
    setFullScreen(elem ? true : false);

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };
  const offFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
    setFullScreen(false);
  };
  const dispatch = useDispatch();
  const logout = () => {
    persistor.purge();
    dispatch(apiSlice.util.resetApiState());
    dispatch(resetCredentials());
  };

  const changeDarkModeSesstings = async () => {
    try {
      let obj = {
        darkModeOn: background.value === "light" ? true : false,
        _id: user._id,
        role: user.role,
      };

      const userData = await update(obj).unwrap();

      if (userData) {
        let credsObj = {
          user: userData.user,
        };

        dispatch(setCredentials(credsObj));
      }
    } catch (error) {
      console.log("update : ", error);
    }
  };

  useEffect(() => {
    if (user && user.role) {
      let role = user.role;
      let menu = menuFinder(role, subscriptions);
      let pathname = location.pathname.replace("/", "");
      let item = menu.find((elt) => elt.link === pathname);
      let detailMenu = DetailsMenu[pathname];
      if (item) {
        setPageName(item.item);
      }
      if (detailMenu) {
        setPageName(detailMenu);
      }
    }
  }, [location, user]);

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {pageName}
              </div>
            </div>

            <ul className="navbar-nav header-right">
              <li className="nav-item dropdown notification_dropdown">
                {isLoading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <Link
                    className="nav-link dz-theme-mode"
                    onClick={async () => {
                      try {
                        await changeDarkModeSesstings();
                        changeBackground(
                          background.value === "dark"
                            ? { value: "light", label: "Light" }
                            : { value: "dark", label: "Dark" }
                        );
                      } catch (error) {
                        console.log("changeDarkModeSesstings : ", error);
                      }
                    }}
                  >
                    {background.value === "dark" ? (
                      <i className="fa-solid fa-sun"></i>
                    ) : (
                      <i className="fa-solid fa-moon"></i>
                    )}
                  </Link>
                )}
              </li>
              {/* {fullScreen ? (
                <li
                  className="nav-item dropdown notification_dropdown"
                  onClick={() => offFullScreen()}
                >
                  <Link className="nav-link dz-fullscreen active" to="#">
                    <svg
                      id="icon-full"
                      viewBox="0 0 24 24"
                      width={26}
                      height={26}
                      stroke="currentColor"
                      strokeWidth={2}
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="css-i6dzq1"
                    >
                      <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3" />
                    </svg>
                    <svg
                      id="icon-minimize"
                      width={26}
                      height={26}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-minimize"
                    >
                      <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3" />
                    </svg>
                  </Link>
                </li>
              ) : (
                <li className="nav-item dropdown notification_dropdown">
                  <Link
                    className="nav-link dz-fullscreen"
                    to="#"
                    onClick={() => onFullScreen()}
                  >
                    <svg
                      id="icon-full"
                      viewBox="0 0 24 24"
                      width={26}
                      height={26}
                      stroke="currentColor"
                      strokeWidth={2}
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="css-i6dzq1"
                    >
                      <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3" />
                    </svg>
                    <svg
                      id="icon-minimize"
                      width={26}
                      height={26}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-minimize"
                    >
                      <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3" />
                    </svg>
                  </Link>
                </li>
              )} */}
              {/* <li className="nav-item dropdown notification_dropdown">
                <Link
                  className="nav-link bell bell-link"
                  to="#"
                  onClick={() => onNote()}
                >
                  <div className="">
                    <i className="flaticon-381-pad"></i>
                  </div>
                </Link>
              </li>

              <li className="nav-item dropdown notification_dropdown">
                <Dropdown className="no-carat transparrent">
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className="nav-link  ai-icon i-false"
                  >
                    <div className="">
                      <i className="flaticon-381-ring"></i>
                      <div className="pulse-css"></div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                    <PerfectScrollbar className="deznav-scroll">
                      <div
                        id="DZ_W_Notification1"
                        className="widget-media dz-scroll p-3"
                        style={{ height: "380px" }}
                      >
                        <ul className="timeline">
                          <li>
                            <div className="timeline-panel">
                              <div className="media mr-2">
                                <img src={avatar} width={50} alt />
                              </div>
                              <div className="media-body">
                                <h6 className="mb-1">
                                  Dr sultadsss Send you Photo
                                </h6>
                                <small className="d-block">
                                  29 July 2020 - 02:26 PM
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-panel">
                              <div className="media mr-2 media-info">KG</div>
                              <div className="media-body">
                                <h6 className="mb-1">
                                  Resport created successfully
                                </h6>
                                <small className="d-block">
                                  29 July 2020 - 02:26 PM
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-panel">
                              <div className="media mr-2 media-success">
                                <i className="fa fa-home"></i>
                              </div>
                              <div className="media-body">
                                <h6 className="mb-1">
                                  Reminder : Treatment Time!
                                </h6>
                                <small className="d-block">
                                  29 July 2020 - 02:26 PM
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-panel">
                              <div className="media mr-2">
                                <img src={avatar} width={50} alt />
                              </div>
                              <div className="media-body">
                                <h6 className="mb-1">
                                  Dr sultads Send you Photo
                                </h6>
                                <small className="d-block">
                                  29 July 2020 - 02:26 PM
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-panel">
                              <div className="media mr-2 media-danger">KG</div>
                              <div className="media-body">
                                <h6 className="mb-1">
                                  Resport created successfully
                                </h6>
                                <small className="d-block">
                                  29 July 2020 - 02:26 PM
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-panel">
                              <div className="media mr-2 media-primary">
                                <i className="fa fa-home"></i>
                              </div>
                              <div className="media-body">
                                <h6 className="mb-1">
                                  Reminder : Treatment Time!
                                </h6>
                                <small className="d-block">
                                  29 July 2020 - 02:26 PM
                                </small>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </PerfectScrollbar>
                    <Link className="all-notification" to={"#"}>
                      See all notifications <i className="ti-arrow-right"></i>
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
              </li> */}

              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  as={Link}
                  variant=""
                  className="nav-link i-false"
                  to="#"
                  role="button"
                  data-toggle="dropdown"
                >
                  <div className="header-info">
                    <span>
                      {user.firstName} {user.lastName}
                    </span>
                    <small>{user.role}</small>
                  </div>
                  <img src={user.photo || avatarEmpty} width={20} alt="" />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  alignRight
                  className="dropdown-menu dropdown-menu-right"
                >
                  <Link to="/profil" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ml-2">Profil</span>
                  </Link>
                  <Link
                    to="/login"
                    className="dropdown-item ai-icon"
                    onClick={logout}
                  >
                    <svg
                      id="icon-logout"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-danger"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                      <polyline points="16 17 21 12 16 7" />
                      <line x1={21} y1={12} x2={9} y2={12} />
                    </svg>
                    <span className="ml-2">Déconnexion </span>
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
