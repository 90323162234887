import React, { useContext, useEffect } from "react";
/// Layout
import Nav from "./nav";
import { ThemeContext } from "../../context/ThemeContext";
import { useLocation, useOutletContext } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useGetUserInfoQuery } from "../../features/auth/authAPISlice";
import { setCredentials } from "../../features/auth/authSlice";

export default function MainLayout({ component: Component }) {
  const { sidebarOpen } = useContext(ThemeContext);

  const dispatch = useDispatch();
  const [user] = useOutletContext();
  const location = useLocation();
  const { data: userInfo, isLoading: isGetUserInfoLoading } =
    useGetUserInfoQuery({
      model:
        user.role === "PATIENT"
          ? "patients"
          : user.role === "DOCTOR"
          ? "doctors"
          : "admins",
      id: user ? user._id : null,
    });

  useEffect(() => {
    if (
      !isGetUserInfoLoading &&
      userInfo.user &&
      userInfo.user.patient &&
      userInfo.user.patient.subscriptions &&
      userInfo.user.patient.subscriptions.length > 0
    ) {
      let credsObj = {
        user: userInfo.user,
        subscriptions: userInfo.user.patient.subscriptions,
      };
      dispatch(setCredentials(credsObj));
    }
  }, [userInfo]);

  return (
    <>
      {location.search && location.search.includes("_id") ? (
        <>{Component}</>
      ) : (
        <div id="main-wrapper" className="show">
          <Nav />
          <div
            className={`content-body ${!sidebarOpen ? "sidebar_closed" : ""}`}
          >
            <div className="container-fluid" style={{ minHeight: "70vh" }}>
              {Component}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
