import { apiSlice } from "../../api/apiSlice";

export const documentsAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllDocuments: builder.query({
      query: (page = 1, limit = 10) => ({
        url: `/documents/all?page=${page}&limit=${limit}`,
      }),
      providesTags: ["Documents"],
    }),
    getDocumentById: builder.query({
      query: (id) => {
        return {
          url: `/documents/${id}`,
        };
      },
    }),
    getDocumentBySessionId: builder.query({
      query: ({ session, user, role, page = 1, limit = 10 }) => {
        let filter = {
          session: session,
        };
        if (role === "PATIENT") {
          filter = {
            ...filter,
            users: user,
          };
        }
        return {
          url: `/documents/filter?page=${page}&limit=${limit}`,
          method: "POST",
          body: {
            filter,
          },
        };
      },
      providesTags: ["DocumentBySession"],
    }),
    getDocumentByParticipant: builder.query({
      query: ({ filter, page = 1, limit = 10, sort }) => {
        return {
          url: `/documents/filter?page=${page}&limit=${limit}`,
          method: "POST",
          body: {
            filter,
            sort,
          },
        };
      },
      providesTags: ["DocumentBySession"],
    }),
    updateDocumentInfo: builder.mutation({
      query: (credentials) => {
        let { _id, ...rest } = credentials;
        let payload = rest;
        const formData = new FormData();
        if (rest.documents && typeof rest.documents !== "string") {
          let { documents, ..._rest } = rest;
          for (let key of Object.keys(_rest)) {
            formData.append(key, rest[key]);
          }
          for (let key of Object.keys(documents)) {
            formData.append(key, rest[key]);
          }
          payload = formData;
        }
        return {
          url: `/documents/${_id}`,
          method: "PUT",
          body: payload,
        };
      },
    }),
    addDocument: builder.mutation({
      query: (credentials) => {
        let payload = credentials;
        return {
          url: `/documents/create`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Documents", "DocumentBySession"],
    }),
    deleteDocument: builder.mutation({
      query: (credentials) => {
        return {
          url: `/documents/${credentials._id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Documents", "DocumentBySession"],
    }),
  }),
});

export const {
  useGetAllDocumentsQuery,
  useGetDocumentByIdQuery,
  useGetDocumentByParticipantQuery,
  useUpdateDocumentInfoMutation,
  useAddDocumentMutation,
  useDeleteDocumentMutation,
  useGetDocumentBySessionIdQuery,
} = documentsAPISlice;
