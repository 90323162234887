import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import fileDownload from "js-file-download";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Viewer } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import DocViewer, {
  BMPRenderer,
  DocRenderer,
  HTMLRenderer,
  IHeaderOverride,
  JPGRenderer,
  PDFRenderer,
  PNGRenderer,
  TIFFRenderer,
  TXTRenderer,
} from "@cyntler/react-doc-viewer";

import DownloadLink from "react-download-link";
import axios from "axios";
import { toast } from "react-toastify";

const MyCustomImageRenderer = ({ mainState: { currentDocument } }) => {
  if (!currentDocument) return null;

  return (
    <div id="my-png-renderer">
      <img id="png-img" src={currentDocument.fileData} />
    </div>
  );
};

MyCustomImageRenderer.fileTypes = [
  "png",
  "PNG",
  "HEIC",
  "JPEG",
  "JPG",
  "image/png",
];
MyCustomImageRenderer.weight = 1;

const renderError = (error) => {
  console.log("error here ", error);
  let message = "";

  switch (error.name) {
    case "InvalidPDFException":
      message = "Le document n'est pas valide ou est corrompu";
      break;
    case "MissingPDFException":
      message = "Le document est manquant";
      break;
    case "UnexpectedResponseException":
      message = "Impossible de charger le document";
      break;
    default:
      message =
        "Impossible de charger le document pour le moment, mais vous pouvez le télécharger en cliquant sur le bouton de téléchargement.";
      break;
  }

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#e53e3e",
          borderRadius: "0.25rem",
          color: "#fff",
          padding: "0.5rem",
        }}
      >
        {message}
      </div>
    </div>
  );
};

const CustomHeader = (state) => {
  const getDataFromURL = async (url) => {
    const { data } = await axios.get(url, {
      responseType: "blob",
    });
    return data;
  };

  return (
    <>
      {state.documents && state.documents.length > 0 && (
        <div className="image-downloader">
          <i className="fa-solid fa-download"></i>
          <DownloadLink
            label="Télécharger"
            filename={(state.documents[0].uri || "")
              .substring((state.documents[0].uri || "").lastIndexOf("/") + 1)
              .replace("?origin=" + window.location.host, "")}
            exportFile={async () =>
              await getDataFromURL(state.documents[0].uri)
            }
          />
        </div>
      )}
    </>
  );
};

export const DocView = ({ url, hasWaterMark, fileType }) => {
  const url2blob = async (url) => {
    try {
      const data = await fetch(url);
      const blob = await data.blob();

      setDispalyedURlL({
        url,
        type: blob.type,
        location: "local",
      });

    } catch (err) {
      console.error(err);
    }
  };

  const [displayedURL, setDispalyedURlL] = useState();

  useEffect(() => {
    if (String(url) === "null") {
      setDispalyedURlL(null);
    }
    if (url && url?.includes("blob:")) {
      url2blob(url);
    }
    if (url && !url?.includes("blob:")) {
      setDispalyedURlL({
        url: url + "?origin=" + window.location.host,
        type: url.split(".").pop(),
        location: "remote",
      });
    }
  }, [url]);

  const toolbarPluginInstance = toolbarPlugin({
    getFilePlugin: {
      fileNameGenerator: (file) => {
        return `${String(url).toUpperCase()}`;
      },
    },
    searchPlugin: {
      keyword: "PDF",
    },
  });
  const { Toolbar } = toolbarPluginInstance;

  return (
    <>
      {displayedURL && (
        <>
          {displayedURL?.location === "local" ? (
            <>
              {displayedURL.type && (
                <>
                  {displayedURL?.type.toLowerCase().includes("pdf") ? (
                    <>
                      <Toolbar />
                      <Viewer
                        fileUrl={displayedURL.url}
                        plugins={[toolbarPluginInstance]}
                        renderError={renderError}
                      />
                    </>
                  ) : (
                    <>
                      <DocViewer
                        documents={[
                          {
                            uri: displayedURL.url,
                            fileType: displayedURL.type,
                          },
                        ]}
                        pluginRenderers={[
                          BMPRenderer,
                          HTMLRenderer,
                          JPGRenderer,
                          PDFRenderer,
                          PNGRenderer,
                          TIFFRenderer,
                          TXTRenderer,
                          MyCustomImageRenderer,
                        ]}
                        config={{
                          noRenderer: {
                            overrideComponent: renderError,
                          },
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {displayedURL.type && (
                <>
                  {displayedURL?.type.toLowerCase().includes("pdf") ? (
                    <>
                      <Toolbar />
                      <Viewer
                        fileUrl={displayedURL.url}
                        plugins={[toolbarPluginInstance]}
                        renderError={renderError}
                      />
                    </>
                  ) : (
                    <>
                      <DocViewer
                        documents={[
                          {
                            uri: displayedURL.url,
                            fileType: displayedURL.type,
                          },
                        ]}
                        pluginRenderers={[
                          BMPRenderer,
                          HTMLRenderer,
                          JPGRenderer,
                          PDFRenderer,
                          PNGRenderer,
                          TIFFRenderer,
                          TXTRenderer,
                          MyCustomImageRenderer,
                        ]}
                        config={{
                          noRenderer: {
                            overrideComponent: renderError,
                          },
                          header: {
                            disableFileName: true,
                            overrideComponent: CustomHeader,
                          },
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

const extensionsSupported = [
  "pdf",
  "bmp",
  "html",
  "jpg",
  "jpeg",
  "png",
  "tiff",
  "txt",
];

export default function DocumentViewer({
  url,
  viewModal,
  setUrl,
  setViewModal,
  hasWaterMark = false,
  fileType = "",
}) {
  const handleDownload = async (href, name) => {
    const { data } = await axios.get(href, {
      responseType: "blob",
    });

    if (data) {
      fileDownload(data, name);
      setViewModal(false);
      setUrl(null);
    }
  };

  const [extension, setExtension] = useState("pdf");

  useEffect(() => {
    if (url && url.substring(url.lastIndexOf(".") + 1)) {
      let ext = url.substring(url.lastIndexOf(".") + 1);
      setExtension(ext);
      if (
        !extensionsSupported.includes(ext.toLowerCase()) &&
        !url.includes("blob:")
      ) {
        toast.warning("Format de fichier non supporté");
        handleDownload(url, new URL(url).pathname.split("/").pop() || "");
      }
    }
  }, [url]);

  return (
    <>
      {extension && !extensionsSupported.includes(extension.toLowerCase()) ? (
        <></>
      ) : (
        <Modal className="modal fade view-document-modal" show={viewModal}>
          <div className="modal-content">
            <div className="modal-header text-center justify-content-center">
              <h5 className="modal-title"> Prévisualisation du fichier</h5>
              <Button
                variant=""
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => {
                  setViewModal(false);
                  setUrl(null);
                }}
              >
                <span>×</span>
              </Button>
            </div>
            <div className="modal-body">
              <DocView
                url={url}
                hasWaterMark={hasWaterMark}
                fileType={fileType}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
