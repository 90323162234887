import { apiSlice } from "../../api/apiSlice";

export const patientsAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPatients: builder.query({
      query: (page = 1, limit = 10) => ({
        url: `/patients/all?page=${page}&limit=${limit}`,
      }),
      providesTags: ["Patients"],
    }),
    getPatientById: builder.query({
      query: (id) => {
        return {
          url: `/patients/${id}`,
        };
      },
      providesTags: ["SinglePatient"],
    }),
    updatePatientInfo: builder.mutation({
      query: (credentials) => {
        let { _id, ...rest } = credentials;
        let payload = rest;
        const formData = new FormData();
        if (rest.photo && typeof rest.photo !== "string") {
          for (let key of Object.keys(rest)) {
            formData.append(key, rest[key]);
          }
          payload = formData;
        }
        return {
          url: `/patients/${_id}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["Patients","SinglePatient"],
    }),
    addPatient: builder.mutation({
      query: (credentials) => {
        let payload = credentials;
        const formData = new FormData();
        if (credentials.photo && typeof credentials.photo !== "string") {
          for (let key of Object.keys(credentials)) {
            formData.append(key, credentials[key]);
          }
          payload = formData;
        }
        return {
          url: `/patients/create`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Patients"],
    }),
    deletePatient: builder.mutation({
      query: (credentials) => {
        return {
          url: `/patients/${credentials._id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Patients"],
    }),
  }),
});

export const {
  useGetAllPatientsQuery,
  useGetPatientByIdQuery,
  useUpdatePatientInfoMutation,
  useAddPatientMutation,
  useDeletePatientMutation,
} = patientsAPISlice;
