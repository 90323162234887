import React, { Fragment } from "react";
import { useLocation, useParams } from "react-router-dom";
import DoctorDetails from "./DoctorDetails";
import PatientDetails from "./PatientDetails";

const PersonDetails = () => {
  const location = useLocation();
  const { id } = useParams();
  return (
    <Fragment>
      {id && location && location.pathname.includes("psychologues") && (
        <DoctorDetails id={id} />
      )}
       {id && location && location.pathname.includes("patients") && (
        <PatientDetails id={id} />
      )}
    </Fragment>
  );
};

export default PersonDetails;
