import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import DoctorAdd from "./DoctorAdd";
import PatientAdd from "./PatientAdd";

const PersonAdd = () => {
  const location = useLocation();
  return (
    <Fragment>
      {location && location.pathname.includes("psychologues") && (
        <>
          <DoctorAdd />
        </>
      )}
      {location && location.pathname.includes("patients") && (
        <>
          <PatientAdd />
        </>
      )}
    </Fragment>
  );
};

export default PersonAdd;
