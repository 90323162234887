import React, { useEffect } from "react";
import moment from 'moment'
import 'moment/locale/fr'  // without this line it didn't work


/// Components
import Markup from "./jsx";

/// Style
import "./scss/main.scss";
import { withResizeDetector } from "react-resize-detector";
import ThemeContextProvider from "./context/ThemeContext";

import { persistor, store } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { Worker } from "@react-pdf-viewer/core";
import "react-toastify/dist/ReactToastify.css";
moment.locale('fr')

const App = () => {
  return (
    <Worker
      workerUrl={`https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js`}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            theme="colored"
            closeButton
          />
          <ThemeContextProvider>
            <Markup />
          </ThemeContextProvider>
        </PersistGate>
      </Provider>
    </Worker>
  );
};

export default withResizeDetector(App);
