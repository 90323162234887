import React, { useEffect, useState } from "react";
import {
  useGetAllPacksQuery,
  useLinkPackToPatientMutation,
} from "../../../features/packs/packsAPISlice";
import { useNavigate, useOutletContext } from "react-router-dom";
import { setCredentials } from "../../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { Accordion, Card, Col } from "react-bootstrap";
import PacksManagementTable from "./PacksManagementTable";
import { toast } from "react-toastify";

function Packs() {
  let [limit, _] = useState(10);
  let [page, __] = useState(1);
  const [activeDefault, setActiveDefault] = useState(-1);
  const [user, ___, subscriptions] = useOutletContext();

  const { data: packs, isLoading } = useGetAllPacksQuery(page, limit);

  const navigate = useNavigate();
  return (
    <React.Fragment>
      {isLoading ? (
        <></>
      ) : (
        <div className="row">
          <Col xl="5">
            <Card>
              <Card.Header className="d-block card-header">
                <Card.Title>Nos différents Packs</Card.Title>
                <Card.Text className="m-0 subtitle">
                  Vous pouvez souscrire à un ou plusieurs packs
                </Card.Text>
              </Card.Header>
              <Card.Body className="card-body">
                <Accordion className="accordion accordion-header-bg ">
                  {packs.packs.map((d, i) => (
                    <div className="accordion__item" key={i}>
                      <Accordion.Toggle
                        as={Card.Text}
                        eventKey={`${i}`}
                        className={`accordion__header ${
                          activeDefault === i ? "" : "collapsed"
                        } accordion__header--primary`}
                        onClick={() =>
                          setActiveDefault(activeDefault === i ? -1 : i)
                        }
                      >
                        <span className="accordion__header--text">
                          {d.title} ({d.price} € / séance)
                        </span>
                        <span className="accordion__header--indicator"></span>
                      </Accordion.Toggle>

                      <Accordion.Collapse eventKey={`${i}`}>
                        <>
                          {d.description.split("<br/>").map((des, index) => (
                            <div
                              className="accordion__body--text"
                              index={index}
                            >
                              {des}
                            </div>
                          ))}
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-primary text-white"
                              onClick={() => {
                                let activeSub =
                                  subscriptions[subscriptions.length - 1];
                                if (
                                  activeSub &&
                                  activeSub.pack &&
                                  activeSub.pack.ref
                                ) {
                                  let packLimit = 6;
                                  let currentSubscriptions = (
                                    activeSub.sessions || []
                                  ).length;
                                  if (
                                    activeSub.pack.ref === "1I" ||
                                    activeSub.pack.ref === "OnSite"
                                  ) {
                                    packLimit = 1;
                                  }

                                  if (currentSubscriptions < packLimit) {
                                    toast.warning(
                                      "Vous devez utiliser la totalité de votre pack avant d'en acheter un autre!"
                                    );
                                    return;
                                  }
                                }
                                navigate(`/checkout/${d._id}`);
                              }}
                            >
                              <i className="fa fa-bell-o"></i> Acheter
                              maintenant
                            </button>
                          </div>
                        </>
                      </Accordion.Collapse>
                    </div>
                  ))}
                </Accordion>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="7">
            <div className="card">
              <div className="card-body">
                {subscriptions && subscriptions.length > 0 && (
                  <PacksManagementTable
                    subscriptions={[...subscriptions].reverse()}
                  />
                )}
              </div>
            </div>
          </Col>
        </div>
      )}
    </React.Fragment>
  );
}

export default Packs;
