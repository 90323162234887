import { apiSlice } from "../../api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/login",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    update: builder.mutation({
      query: (credentials) => {
        const { role, _id, ...rest } = credentials;
        let path = "admins";
        switch (role) {
          case "ADMIN":
            path = "admins";
            break;

          case "PATIENT":
            path = "patients";
            break;

          case "DOCTOR":
            path = "doctors";
            break;

          default:
            path = "admins";
            break;
        }
        let payload = rest;
        const formData = new FormData();
        if (rest.photo && typeof rest.photo !== "string") {
          for (let key of Object.keys(rest)) {
            formData.append(key, rest[key]);
          }
          payload = formData;
        }
        return {
          url: `/${path}/${_id}`,
          method: "PUT",
          body: payload,
        };
      },
    }),
    getUserInfo: builder.query({
      query: ({ model, id }) => ({
        url: `/${model}/${id}`,
      }),
      providesTags: ["User"],
    }),
  }),
});

export const { useLoginMutation, useUpdateMutation, useGetUserInfoQuery } =
  authApiSlice;
