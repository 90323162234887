import React, { useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {fr} from 'date-fns/locale'

function BasicDatePicker({
  label = "",
  selectedDate,
  setSelectedDate,
  className,
  disableFuture = false
}) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} adapterLocale={fr} locale={fr}>
      <DatePicker
        autoOk
        label={label}
        clearable
        format="dd/MM/yyyy"
        disableFuture={disableFuture}
        value={selectedDate}
        onChange={setSelectedDate}
        className={className}
        InputProps ={{
          disableUnderline: true,
        }}
        showTodayButton
        okLabel="ok"
        clearLabel="Vider"
        todayLabel="Aujourd'hui"
        cancelLabel="Annuler"
      />
    </MuiPickersUtilsProvider>
  );
}

export default BasicDatePicker;
