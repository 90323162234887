import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../config";
import { toast } from "react-toastify";

const { API_URL } = config[process.env.NODE_ENV];

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("crm_token", token);
    }
    return headers;
  },
});

const psotMessageStopers = [
  "login",
  "filter",
  "availability",
  "subscribeToSession",
  "createCheckoutSession",
  "getRoomsByParticipants",
  "linkPackToPatient",
  "rooms/create",
  "getAvailability",
  "checkPackAlreadyPurchased",
  "sessionStatus",
];

const psotMessageStopersCheck = (url) => {
  return psotMessageStopers.every((elt) => !url.includes(elt));
};

const baseQueryWithBuilder = async (args, api, extraOptions) => {
  try {
    let result = await baseQuery(args, api, extraOptions);
    if (
      result.meta.request.method === "POST" &&
      [200].includes(result.meta.response.status) &&
      psotMessageStopersCheck(result.meta.request.url)
    ) {
      let msg = "Opération réalisée avec succès!";
      if (result.data.error) {
        msg = result.data.msg;
        toast.warning(msg);
      } else {
        toast.success(msg);
      }
    }
    if (
      result.meta.request.method === "PUT" &&
      [200].includes(result.meta.response.status)
    ) {
      toast.success("Modification effectuée avec succès!");
    }
    if (
      result.meta.request.method === "DELETE" &&
      [200].includes(result.meta.response.status)
    ) {
      toast.success("Suppression effectuée avec succès!");
    }
    if (result.error) {
      console.log("error : ", result.error);
      toast.warn("Oups, un problème est survenu, veuillez réessayer.");
    }
    return result;
  } catch (error) {
    console.log("baseQueryWithBuilder : ", error);
  }
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithBuilder,
  endpoints: (builder) => ({}),
});
