import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import logo from "../../../images/LOGO_IMS.png";
import miniLogo from "../../../images/LOGO_IMS_MINI.png";

const NavHader = ({ sidebarOpen, setSideBarOpen, openMenuToggle }) => {
  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo">
        {sidebarOpen ? (
          <img className="logo-abbr" src={logo} alt="" />
        ) : (
          <img className="logo-abbr" src={miniLogo} alt="" />
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setSideBarOpen(!sidebarOpen);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${sidebarOpen ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
