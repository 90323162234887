import React from "react";
import { Link } from "react-router-dom";

const Error403 = ({ page = { link: "/login", page: "Connexion" } }) => {
  return (
    <div className="mid-page">
      <div className="authincation h-100 p-meddle">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center ">
            <div className="col-md-6">
              <div className="form-input-content text-center error-page">
                <h4>
                  <i className="fa fa-times-circle text-danger" /> Accès
                  interdit !
                </h4>
                <p>
                  Vous n'avez pas l'autorisation de consulter cette ressource.
                </p>
                <div>
                  <Link className="btn btn-primary" to={page.link}>
                    {page.page}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error403;
