import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  useDeleteDoctorMutation,
  useGetAllDoctorsQuery,
} from "../../../features/doctors/doctorsAPISlice";
import ProfileDatatable from "../../components/table/ProfileDatatable";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";

function Doctors() {
  let [limit, setLimit] = useState(10);
  let [page, setPage] = useState(1);
  const [searchParams] = useSearchParams();
  const [deleteModal, setDeleteModal] = useState(false);
  const [doctorID, setDoctorID] = useState(null);
  const { data: doctors, isLoading } = useGetAllDoctorsQuery(page, limit);
  const [deleteDoctor, { isLoading: deleteDoctorIsLoading }] =
    useDeleteDoctorMutation();
  useEffect(() => {
    if (searchParams.get("page")) {
      setPage(parseInt(searchParams.get("page")));
    }
    if (searchParams.get("limit")) {
      setLimit(parseInt(searchParams.get("limit")));
    }
  }, [searchParams]);

  return (
    <React.Fragment>
      <div className="form-head d-flex mb-3 mb-lg-5 justify-content-end">
        <Link to="/psychologues/ajouter" className="btn btn-danger">
          + Nouveau psychologue
        </Link>
      </div>

      {isLoading ? (
        <div className="d-flex justify-content-center">
          <div className=" spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="card">
          <div className="card-body">
            <ProfileDatatable
              data={{
                data: doctors.users.map((elt) => ({
                  photo: elt.photo || "",
                  _id: elt._id,
                  lastName: elt.lastName,
                  firstName: elt.firstName,
                  phone: elt.phone,
                  email: elt.email,
                  createdAt: elt.createdAt
                    ? moment(elt.createdAt).format("DD/MM/YYYY")
                    : "",
                })),
                columns: [
                  "",
                  "_id",
                  "Nom",
                  "Prénom",
                  "Téléphone",
                  "Email",
                  "Créé à",
                  "Action",
                ],
              }}
              total={doctors.totalPages}
              setDeleteModal={setDeleteModal}
              setID={setDoctorID}
              endpoint="psychologues"
            />
          </div>
        </div>
      )}

      <Modal className="modal fade" show={deleteModal}>
        <div className="modal-content">
          <div className="modal-header text-center justify-content-center">
            <h5 className="modal-title">Supprimer un psychologue</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setDeleteModal(false)}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-center">
              <p> Êtes-vous sûr de vouloir supprimer ce psychologue ?</p>
            </div>
            <div className="d-flex justify-content-center">
              <div className="form-group">
                {deleteDoctorIsLoading ? (
                  <div className="d-flex justify-content-center">
                    <div className=" spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <input
                    type="submit"
                    value="Supprimer"
                    className="submit btn btn-danger"
                    onClick={async () => {
                      try {
                        await deleteDoctor({
                          _id: doctorID,
                        }).unwrap();
                        setDeleteModal(false);
                      } catch (error) {
                        console.log("deleteDoctor : ", error);
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default Doctors;
