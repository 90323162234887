import React, { Fragment, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useGetAllPatientsQuery } from "../../../features/patients/patientsAPISlice";
import DateTimePicker from "../../components/Forms/Pickers/MetarialDateAndTime";

import moment from "moment";
import Select from "react-select";
import {
  useGetSessionByIdQuery,
  useUpdateSessionInfoMutation,
} from "../../../features/sessions/sessionsAPISlice";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  useGetAllDoctorsQuery,
} from "../../../features/doctors/doctorsAPISlice";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import {
  useAddDocumentMutation,
  useDeleteDocumentMutation,
  useGetDocumentBySessionIdQuery,
} from "../../../features/documents/documentsAPISlice";
import ProfileDatatable from "../../components/table/ProfileDatatable";
import DocumentViewer from "../../components/DocumentViewer";

// import { SRLWrapper } from "simple-react-lightbox";

const SessionDetails = () => {
  const [user] = useOutletContext();
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { dirtyFields },
  } = useForm();
  const { id } = useParams();
  let [limit, setLimit] = useState(10);
  let [page, setPage] = useState(1);
  const { data: sessionData, isLoading: sessionDataIsLoading } =
    useGetSessionByIdQuery(id);
  const { data: doctors, isLoading: doctorsIsLoading } = useGetAllDoctorsQuery(
    0,
    0
  );
  const { data: patients, isLoading: patientsIsLoading } =
    useGetAllPatientsQuery(0, 0);
  const [updateSessionInfo, { updateSessionInfoIsLoading }] =
    useUpdateSessionInfoMutation();
  const {
    data: documentsBySessionId,
    isLoading: documentsBySessionIdIsLoading,
  } = useGetDocumentBySessionIdQuery({
    session: id,
    user: user.id,
    role: user.role,
    page,
    limit,
  });
  const [deleteDocument, { isLoading: deleteDocumentIsLoading }] =
    useDeleteDocumentMutation();

  const [participantsLoading, setParticipantsLoading] = useState(true);

  const [searchParams] = useSearchParams();
  const [files, setFiles] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [documentID, setDocumentID] = useState(null);

  const [addDocument] = useAddDocumentMutation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [participants, setParticipants] = useState([]);
  const [fileUsers, setFileUsers] = useState([]);
  const [addFilesModal, setAddFilesModal] = useState(false);
  const [activeToggle, setActiveToggle] = useState("details");

  useEffect(() => {
    if (sessionData) {
      let { session } = sessionData;
      let obj = {
        title: session.title,
        description: session.description,
        link: session.link,
      };
      if (session.startDate) {
        setStartDate(session.startDate);
      }
      if (session.endDate) {
        setEndDate(session.endDate);
      }
      if (session.participants && session.participants.length > 0) {
        let _participants = session.participants.map((elt) => ({
          label:
            elt.firstName || elt.lastName
              ? `${elt.firstName || ""} ${elt.lastName || ""}`
              : elt.email,
          value: elt.id,
        }));
        if (user.role === "DOCTOR") {
          _participants = _participants.filter((elt) => elt.value !== user._id);
        }
        setParticipants(_participants);
      }
      reset(obj);
    }
  }, [sessionData]);

  useEffect(() => {
    if (searchParams.get("page")) {
      setPage(parseInt(searchParams.get("page")));
    }
    if (searchParams.get("limit")) {
      setLimit(parseInt(searchParams.get("limit")));
    }
  }, [searchParams]);

  const participantsLabelCreator = (role) => {
    switch (role) {
      case "DOCTOR":
        return "Patients";

      case "PATIENT":
        return "Psychologues";

      default:
        return "Participants";
    }
  };

  const participantsOptionsCreator = (role) => {
    switch (role) {
      case "DOCTOR":
        return [
          ...patients.users.map((elt) => ({
            label:
              elt.firstName || elt.lastName
                ? `${elt.firstName || ""} ${elt.lastName || ""}`
                : elt.email,
            value: elt.id,
          })),
        ];

      case "PATIENT":
        return [
          ...doctors.users.map((elt) => ({
            label:
              elt.firstName || elt.lastName
                ? `${elt.firstName || ""} ${elt.lastName || ""}`
                : elt.email,
            value: elt.id,
          })),
        ];

      default:
        return [
          ...doctors.users.map((elt) => ({
            label:
              elt.firstName || elt.lastName
                ? `${elt.firstName || ""} ${elt.lastName || ""}`
                : elt.email,
            value: elt.id,
          })),
          ...patients.users.map((elt) => ({
            label:
              elt.firstName || elt.lastName
                ? `${elt.firstName || ""} ${elt.lastName || ""}`
                : elt.email,
            value: elt.id,
          })),
        ];
    }
  };

  const participantsLoader = (role) => {
    let value = true;
    switch (role) {
      case "DOCTOR":
        value = patientsIsLoading;
        break;

      case "PATIENT":
        value = doctorsIsLoading;
        break;

      default:
        value = patientsIsLoading || doctorsIsLoading;
        break;
    }
    return value;
  };

  useEffect(() => {
    const val = participantsLoader(user.role);
    setParticipantsLoading(val);
  }, [user, patientsIsLoading, doctorsIsLoading]);

  const onSubmit = async (data) => {
    try {
      let obj = {
        _id: id,
      };

      if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
        toast.error(
          "Une ou plusieurs informations fournies ne sont pas valides!"
        );
        return;
      }
      if (data.title && dirtyFields["title"]) {
        obj = {
          ...obj,
          title: data.title,
        };
      }
      if (data.description && dirtyFields["description"]) {
        obj = {
          ...obj,
          description: data.description,
        };
      }

      if (moment(startDate).isValid()) {
        obj = {
          ...obj,
          startDate: moment(startDate).format(),
        };
      }

      if (moment(endDate).isValid()) {
        obj = {
          ...obj,
          endDate: moment(endDate).format(),
        };
      }

      if (participants && participants.length > 0) {
        let _participants = participants.reduce(
          (acc, curr) => [...acc, curr["value"]],
          []
        );

        if (
          (user.role === "DOCTOR" || user.role === "PATIENT") &&
          !_participants.includes(user._id)
        ) {
          _participants = [..._participants, user._id];
        }

        obj = {
          ...obj,
          participants: _participants,
        };
        
        // const availabilityData = await validateDoctorAvailability({
        //   participants: _participants,
        //   startDate: obj.startDate,
        // }).unwrap();

        // if (!availabilityData.availability) {
        //   toast.error(
        //     "L'un des participants n'est pas disponible à ce temps-là.!"
        //   );
        //   return;
        // }
      }

      if (Object.values(obj).length > 1) {
        const sessionData = await updateSessionInfo(obj).unwrap();
      }
    } catch (error) {
      console.log("onSubmit : ", error);
    }
  };

  const closeFileUploadModal = () => {
    setFiles([]);
    setFileUsers([]);
    setAddFilesModal(false);
  };

  const uploadFiles = async () => {
    try {
      if (files && files.length > 0 && fileUsers && fileUsers.length > 0) {
        for (let file of files) {
          let formData = new FormData();
          formData.append("sessionID", id);
          formData.append("name", file.name);
          formData.append("document", file);
          formData.append(
            "users",
            JSON.stringify(
              fileUsers.reduce((acc, curr) => [...acc, curr.value], [])
            )
          );

          await addDocument(formData).unwrap();
        }
        closeFileUploadModal();
      }
    } catch (error) {
      console.log("onSubmit : ", error);
    }
  };

  const removeFileFromFileList = (file) => {
    let _files = [...files];
    let deletedFileIndex = files.findIndex((elt) => elt.name === file.name);
    if (deletedFileIndex > -1) {
      _files.splice(deletedFileIndex, 1);
    }
    setFiles(_files);
  };

  const getDocumentUrl = (id) => {
    if (documentsBySessionIdIsLoading) {
      return null;
    }
    const docs = documentsBySessionId.documents;
    let doc = docs.find((elt) => elt._id === id);
    if (doc && doc.file) {
      return doc.file;
    } else {
      return null;
    }
  };

  const navigate = useNavigate();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <ul className="nav nav-tabs">
                    <li
                      onClick={() => setActiveToggle("details")}
                      className={`nav-item nav-link ${
                        activeToggle === "details" ? "active show" : ""
                      }`}
                    >
                      Détails
                    </li>
                    <li
                      onClick={() => setActiveToggle("documents")}
                      className={`nav-item nav-link ${
                        activeToggle === "documents" ? "active show" : ""
                      }`}
                    >
                      Documents
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      id="details"
                      className={`tab-pane fade ${
                        activeToggle === "details" ? "active show" : ""
                      }`}
                    >
                      <div className="pt-3">
                        <div className="settings-form">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-row">
                              <div className="form-group col-md-12 row">
                                <div className="form-group col-md-6">
                                  <label>Titre</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    {...register("title")}
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Date de début</label>
                                  <DateTimePicker
                                    selectedDate={startDate}
                                    setSelectedDate={setStartDate}
                                    className="form-control cutom-date-picker"
                                    disabled={user.role === "PATIENT"}
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Date de fin estimée</label>
                                  <DateTimePicker
                                    selectedDate={endDate}
                                    setSelectedDate={setEndDate}
                                    className="form-control cutom-date-picker"
                                    disabled={user.role === "PATIENT"}
                                  />
                                </div>

                                {user.role === "PATIENT" ? (
                                  <div className="form-group col-md-6 disabled">
                                    <label>Participants</label>
                                    <ul className="event-card-participants-list">
                                      {participants.map((elt, key) => (
                                        <li
                                          key={key}
                                          className="event-card-participants-list-item"
                                        >
                                          <i className="fa-solid fa-calendar mr-2"></i>
                                          {elt.label}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ) : (
                                  <>
                                    {!participantsLoading && (
                                      <div className="form-group col-md-6">
                                        <label>
                                          {participantsLabelCreator(user.role)}
                                        </label>
                                        <Select
                                          value={participants}
                                          onChange={(e) => setParticipants(e)}
                                          options={participantsOptionsCreator(
                                            user.role
                                          )}
                                          isMulti
                                          placeholder={""}
                                          className="custom-multi-select"
                                        />
                                      </div>
                                    )}
                                  </>
                                )}

                                {!sessionDataIsLoading &&
                                  sessionData.session.onSite && (
                                    <div className="form-group col-md-6">
                                      <label>Type</label>
                                      <select className="form-control" disabled>
                                        <option value="INDIVIDUAL" selected>
                                          Sur place
                                        </option>
                                      </select>
                                    </div>
                                  )}

                                {!sessionDataIsLoading &&
                                  !sessionData.session.onSite &&
                                  sessionData.session.type &&
                                  ["GROUPED", "INDIVIDUAL"].includes(
                                    sessionData.session.type
                                  ) && (
                                    <div className="form-group col-md-6 session-details-link">
                                      <button
                                        className="event-card-link-button"
                                        type="button"
                                        onClick={() => {
                                          if (
                                            moment(startDate).get("day") !==
                                            moment(new Date()).get("day")
                                          ) {
                                            toast.warning(
                                              "Si la réunion n'a pas lieu aujourd'hui, le lien sera désactivé!"
                                            );
                                            return;
                                          }
                                          navigate(`/consultation/${id}`);
                                        }}
                                        title={""}
                                      >
                                        <span className="list-group-item active">
                                          Lien d'appel vidéo
                                        </span>
                                      </button>
                                    </div>
                                  )}

                                {/* {user.role === "ADMIN" && (
                                  <div className="form-group col-md-6">
                                    <label>Type</label>
                                    <select
                                      className="form-control"
                                      {...register("type")}
                                    >
                                      <option value="INDIVIDUAL">
                                        Individuelle
                                      </option>
                                      <option value="GROUPED">Groupée</option>
                                    </select>
                                  </div>
                                )} */}

                                <div className="form-group  col-md-12">
                                  <label>Description</label>
                                  <textarea
                                    rows="3"
                                    cols="50"
                                    className="form-control"
                                    {...register("description")}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row justify-content-end">
                              {updateSessionInfoIsLoading ? (
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <button
                                  className="btn btn-primary text-right text-white"
                                  type="submit"
                                >
                                  Enregistrer
                                </button>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div
                      id="documents"
                      className={`tab-pane fade ${
                        activeToggle === "documents" ? "active show" : ""
                      }`}
                    >
                      <div className="pt-3">
                        <div className="settings-form">
                          {(user.role === "ADMIN" ||
                            user.role === "DOCTOR") && (
                            <div className="d-flex mb-3 mb-lg-5 justify-content-end">
                              <button
                                className="btn btn-primary text-white"
                                onClick={() => setAddFilesModal(true)}
                              >
                                Ajouter des fichiers
                              </button>
                            </div>
                          )}

                          {!documentsBySessionIdIsLoading && (
                            <ProfileDatatable
                              data={{
                                data: documentsBySessionId.documents.map(
                                  (elt) => ({
                                    _id: elt._id,
                                    name: elt.name,
                                    url: elt.file || "__",
                                    particpants: elt.users.map((elt) => ({
                                      label:
                                        elt.firstName || elt.lastName
                                          ? `${elt.firstName || ""} ${
                                              elt.lastName || ""
                                            }`
                                          : elt.email,
                                      value: elt.id,
                                    })),
                                  })
                                ),
                                columns: [
                                  "_id",
                                  "Nom",
                                  "Utilisateurs ayant accès",
                                ],
                              }}
                              total={documentsBySessionId.totalPages}
                              setDeleteModal={setDeleteModal}
                              setViewModal={setViewModal}
                              setID={setDocumentID}
                              endpoint="documents"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="modal fade" show={addFilesModal}>
        <div className="modal-content">
          <div className="modal-header text-center justify-content-center">
            <h5 className="modal-title">Ajouter des fichiers</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => closeFileUploadModal()}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body upload-file-modal">
            <div className="row">
              <div className="col-12 input-group mb-3 file-users-select">
                {!participantsLoading && (
                  <div className="form-group col-12">
                    <label>Pour</label>
                    <Select
                      defaultValue={fileUsers}
                      onChange={(e) => setFileUsers(e)}
                      options={participantsOptionsCreator(user.role)}
                      isMulti
                      placeholder={""}
                      className="custom-multi-select"
                    />
                  </div>
                )}
              </div>
              <div className="col-12 input-group mb-3 basic-form custom_file_input custom-file-upload-group">
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      multiple
                      onChange={(e) => {
                        if (!e.target.files) {
                          return;
                        }

                        setFiles(Array.from(e.target.files));
                      }}
                    />
                    <label className="custom-file-label">
                      Télécharger un ou plusieurs fichiers
                    </label>
                  </div>
                </div>
              </div>
              {files.length > 0 && (
                <ul className="col-12 list-group file-list">
                  {files.map((elt, key) => (
                    <li className="list-group-item file-list-item" key={key}>
                      <div className="file-list-item-name">{elt.name}</div>

                      <div className="file-list-item-actions">
                        <i
                          className="fa fa-trash"
                          onClick={() => {
                            removeFileFromFileList(elt);
                          }}
                        ></i>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="d-flex my-3 justify-content-center">
              <button
                className="btn btn-primary text-white"
                onClick={async () => {
                  await uploadFiles();
                }}
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal className="modal fade" show={deleteModal}>
        <div className="modal-content">
          <div className="modal-header text-center justify-content-center">
            <h5 className="modal-title">Supprimer un document</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setDeleteModal(false)}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-center">
              <p> Êtes-vous sûr de vouloir supprimer ce document ?</p>
            </div>
            <div className="d-flex justify-content-center">
              <div className="form-group">
                {deleteDocumentIsLoading ? (
                  <div className="d-flex justify-content-center">
                    <div className=" spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <input
                    type="submit"
                    value="Supprimer"
                    className="submit btn btn-danger"
                    onClick={async () => {
                      try {
                        await deleteDocument({
                          _id: documentID,
                        }).unwrap();
                        setDeleteModal(false);
                      } catch (error) {
                        console.log("deleteDocument : ", error);
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <DocumentViewer
        fileType={
          getDocumentUrl(documentID)
            ? getDocumentUrl(documentID).split(".").pop()
            : null
        }
        url={getDocumentUrl(documentID)}
        setUrl={setDocumentID}
        viewModal={viewModal}
        setViewModal={setViewModal}
      />
    </Fragment>
  );
};

export default SessionDetails;
