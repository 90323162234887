import React, { Fragment, useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
//** Import Image */
import avatarEmpty from "../../../images/avatar_empty.png";

import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useUpdateMutation } from "../../../features/auth/authAPISlice";
import { setCredentials } from "../../../features/auth/authSlice";
import BasicDatePicker from "../../components/Forms/Pickers/MetarialDate";
import moment from "moment";
import Select from "react-select";
import BasicTImePicker from "../../components/Forms/Pickers/MetarialTime";
import ProfileDatatable from "../../components/table/ProfileDatatable";
import {
  useAddAvailabilityMutation,
  useDeleteAvailabilityMutation,
  useFilterAvailabilityQuery,
  useUpdateAvailabilityMutation,
} from "../../../features/availabilities/availabilitiesAPISlice";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { apiSlice } from "../../../api/apiSlice";
import Availability from "./Availability";

const days = [
  {
    morningStart: null,
    morningEnd: null,
    eveningStart: null,
    eveningEnd: null,
  },
  {
    morningStart: null,
    morningEnd: null,
    eveningStart: null,
    eveningEnd: null,
  },
  {
    morningStart: null,
    morningEnd: null,
    eveningStart: null,
    eveningEnd: null,
  },
  {
    morningStart: null,
    morningEnd: null,
    eveningStart: null,
    eveningEnd: null,
  },
  {
    morningStart: null,
    morningEnd: null,
    eveningStart: null,
    eveningEnd: null,
  },
];

const daysOfWeek = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"];

const Profile = () => {
  const { control, register, handleSubmit, reset } = useForm();

  const {
    register: registerMyInfo,
    handleSubmit: handleSubmitMyInfo,
    reset: resetMyInfo,
  } = useForm();

  const dispatch = useDispatch();
  const [user] = useOutletContext();
  const [update, { isLoading }] = useUpdateMutation();
  const [activeToggle, setActiveToggle] = useState("profile-info");
  const [birthDate, setBirthDate] = useState(null);
  const [profilePhto, setProfilePhoto] = useState(avatarEmpty);

  const onProfileInfoSubmit = async (data) => {
    try {
      let obj = {
        ...data,
        _id: user._id,
        role: user.role,
      };

      if (data.gender) {
        obj = {
          ...obj,
          gender: data.gender.value,
        };
      }

      if (profilePhto && profilePhto !== user.photo) {
        obj = {
          ...obj,
          photo: profilePhto,
        };
      }
      const userData = await update(obj).unwrap();

      if (userData) {
        let credsObj = {
          user: userData.user,
        };
        if (
          userData.user.role === "PATIENT" &&
          userData.user.patient.subscriptions &&
          userData.user.patient.subscriptions.length > 0
        ) {
          credsObj = {
            ...credsObj,
            subscriptions: userData.user.patient.subscriptions,
          };
        }

        dispatch(setCredentials(credsObj));
      }
    } catch (error) {
      console.log("update : ", error);
    }
  };

  const onMyInfoSubmit = async (data) => {
    try {
      let obj = {
        ...data,
        _id: user._id,
        role: user.role,
      };
      if (birthDate) {
        obj = {
          ...obj,
          birthDate,
        };
      }
      const userData = await update(obj).unwrap();

      if (userData) {
        let credsObj = {
          user: userData.user,
        };
        if (
          userData.user.role === "PATIENT" &&
          userData.user.patient.subscriptions &&
          userData.user.patient.subscriptions.length > 0
        ) {
          credsObj = {
            ...credsObj,
            subscriptions: userData.user.patient.subscriptions,
          };
        }

        dispatch(setCredentials(credsObj));
      }
    } catch (error) {
      console.log("update : ", error);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      let obj = {
        email: user.email || "",
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        phone: user.phone || "",
        gender: user.gender ? { label: user.gender, value: user.gender } : null,
      };
      if (user.photo && typeof user.photo === "string") {
        setProfilePhoto(user.photo);
      }
      reset(obj);
      if (user.role === "PATIENT") {
        resetMyInfo({
          weight: user.patient.weight,
          height: user.patient.height,
        });
        setBirthDate(user.patient.birthDate);
      }
      if (user.role === "DOCTOR") {
        resetMyInfo({
          diploma: user.doctor.diploma,
          graduationYear: user.doctor.graduationYear,
        });
      }
    }
  }, [user]);

  return (
    <Fragment>
      <div className="card profile-card">
        <div className="card-body">
          <div className="profile-tab row">
            <div className="custom-tab-1 col-md-2 col-sm profile-menu">
              <ul className="nav nav-tabs profile-menu-nav">
                <li
                  className="nav-item profile-menu-nav-item"
                  onClick={() => setActiveToggle("profile-info")}
                >
                  <Link
                    to="#profile-info"
                    data-toggle="tab"
                    className={`nav-link ${
                      activeToggle === "profile-info" ? "active show" : ""
                    } profile-menu-nav-item-link`}
                  >
                    <i className="fa-solid fa-user-gear"></i>
                    <span>Paramètres du compte</span>
                  </Link>
                </li>
                {user && user.role !== "ADMIN" && (
                  <>
                    <li className="nav-item  profile-menu-nav-item">
                      <Link
                        to="#my-info"
                        data-toggle="tab"
                        onClick={() => setActiveToggle("my-info")}
                        className={`nav-link ${
                          activeToggle === "my-info" ? "active show" : ""
                        }  profile-menu-nav-item-link`}
                      >
                        <i className="fa-solid fa-user-pen"></i>
                        <span> Mes informations</span>
                      </Link>
                    </li>
                    <li className="nav-item  profile-menu-nav-item">
                      <Link
                        to="#availability"
                        data-toggle="tab"
                        onClick={() => setActiveToggle("availability")}
                        className={`nav-link ${
                          activeToggle === "availability" ? "active show" : ""
                        }  profile-menu-nav-item-link`}
                      >
                        <i className="fa-solid fa-business-time"></i>
                        <span> Disponibilité</span>
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div className="tab-content col-md-10 profile-data">
              {/* personal info */}
              <div
                id="profile-info"
                className={`tab-pane fade ${
                  activeToggle === "profile-info" ? "active show" : ""
                }`}
              >
                <div className="pt-3">
                  <div className="settings-form">
                    <form onSubmit={handleSubmit(onProfileInfoSubmit)}>
                      <div className="form-row justify-content-center">
                        <div className="form-group col-md-2 author-wrapper">
                          <div className="author-profile">
                            <div className="author-media">
                              <img
                                src={
                                  typeof profilePhto !== "string"
                                    ? URL.createObjectURL(profilePhto)
                                    : profilePhto
                                }
                                alt=""
                              />
                              <div
                                className="upload-link"
                                title=""
                                data-toggle="tooltip"
                                data-placement="right"
                                data-original-title="update"
                                onChange={(e) => {
                                  const files = e.target.files;
                                  setProfilePhoto(files[0]);
                                }}
                              >
                                <input type="file" className="update-flie" />
                                <i className="fa fa-camera"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-row justify-content-center">
                        <div className="form-group col-md-10 row">
                          <div className="form-group  col-md-4">
                            <label>Nom</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("lastName")}
                            />
                          </div>
                          <div className="form-group  col-md-4">
                            <label>Prénom</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("firstName")}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label>Email</label>
                            <input
                              type="email"
                              className="form-control"
                              {...register("email")}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label>Téléphone</label>
                            <input
                              type="phone"
                              className="form-control"
                              {...register("phone")}
                              required
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label>Genre</label>
                            <Controller
                              name="gender"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  options={[
                                    { label: "Homme", value: "Homme" },
                                    { label: "Femme", value: "Femme" },
                                    { label: "Autre", value: "Autre" },
                                  ]}
                                  closeMenuOnSelect={true}
                                  placeholder={""}
                                  classNamePrefix="cutsom_select"
                                />
                              )}
                            />
                          </div>

                          <div className="form-group col-md-12 row justify-content-end">
                            <Link
                              to="/forgot-password"
                              className={"btn-link d-flex align-items-center"}
                            >
                              Mot de passe oublié ?
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="form-row profile-tab-validation-wrapper">
                        <button
                          className="btn btn-outline-danger text-danger"
                          type="button"
                          onClick={() => navigate("/")}
                        >
                          Accueil
                        </button>
                        {isLoading ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <button
                            className="btn btn-danger text-white text-white"
                            type="submit"
                          >
                            Enregistrer
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* patient / doctor info */}
              <div
                id="my-info"
                className={`tab-pane fade ${
                  activeToggle === "my-info" ? "active show" : ""
                }`}
              >
                <div className="pt-3">
                  <div className="settings-form">
                    <form onSubmit={handleSubmitMyInfo(onMyInfoSubmit)}>
                      {user.role === "PATIENT" && (
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Poids (kg)</label>
                            <input
                              type="number"
                              min={0}
                              className="form-control"
                              {...registerMyInfo("weight")}
                            />
                          </div>
                          <div className="form-group  col-md-6">
                            <label>Taille (cm)</label>
                            <input
                              type="number"
                              min={0}
                              className="form-control"
                              {...registerMyInfo("height")}
                            />
                          </div>
                          <div className="form-group  col-md-6">
                            <label>Date de naissance</label>
                            <BasicDatePicker
                              selectedDate={birthDate}
                              setSelectedDate={setBirthDate}
                              className="form-control cutom-date-picker"
                            />
                          </div>
                        </div>
                      )}

                      {user.role === "DOCTOR" && (
                        <div className="form-row">
                          <div className="form-group  col-md-6">
                            <label>Diplôme</label>
                            <input
                              type="text"
                              className="form-control"
                              {...registerMyInfo("diploma")}
                            />
                          </div>
                          <div className="form-group  col-md-6">
                            <label>Année d'obtention du diplôme</label>
                            <input
                              type="number"
                              className="form-control"
                              {...registerMyInfo("graduationYear")}
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-row profile-tab-validation-wrapper">
                        <button
                          className="btn btn-outline-danger text-danger"
                          type="button"
                          onClick={() => navigate("/")}
                        >
                          Accueil
                        </button>
                        {isLoading ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <button
                            className="btn btn-danger text-white  text-white"
                            type="submit"
                          >
                            Enregistrer
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* availability info */}
              <div
                id="availability"
                className={`tab-pane fade ${
                  activeToggle === "availability" ? "active show" : ""
                }`}
              >
                <div className="pt-3">
                  <div className="settings-form">
                    <Availability />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Profile;
