import React, { useEffect } from "react";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import {useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const meeting = new VideoSDKMeeting();

export default function VideoChat() {
  const { user } = useSelector((state) => state.auth);
  const { roomId } = useParams();

  useEffect(() => {
    const config = {
      name: `${user.firstName} ${user.lastName}`,
      meetingId: roomId,
      apiKey: process.env.REACT_APP_VIDEO_CALL_API_KEY,
      containerId: null,
      joinScreen: {
        visible: true,
        title: "Vous pouvez partager le lien en cliquant ici",
        meetingUrl:
          window.location.protocol +
          "//" +
          window.location.host +
          "/consultation/" +
          roomId,
      },
      theme: "LIGHT", // DARK || LIGHT || DEFAULT
      i18n: {
        lang: "fr",
      },
      brandingEnabled: true,
      brandName: "Séance",
      poweredBy: false,
      waitingScreen: {
        text: "Connexion à la réunion...",
      },
      participantCanLeave: true,
      redirectOnLeave: window.location.protocol + "//" + window.location.host,
      micEnabled: true,
      webcamEnabled: true,
      participantCanToggleSelfWebcam: true,
      participantCanToggleSelfMic: true,
      chatEnabled: true,
      screenShareEnabled: true,
      debug: true,
    };
    meeting.init(config);
  }, []);

  return (
    <div>
      <div className="exit-button">
        <a href={window.location.protocol + "//" + window.location.host}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="12"
            viewBox="0 0 24 12"
            fill="none"
          >
            <path
              d="M0.27497 6.47612C0.27525 6.4764 0.275485 6.47672 0.275813 6.47701L5.17444 11.352C5.54142 11.7172 6.135 11.7158 6.5003 11.3488C6.86555 10.9818 6.86414 10.3883 6.49716 10.023L3.20822 6.75001L23.0625 6.75001C23.5803 6.75001 24 6.33029 24 5.81251C24 5.29473 23.5803 4.87501 23.0625 4.87501L3.20827 4.87501L6.49711 1.60201C6.86409 1.23676 6.8655 0.643184 6.50025 0.276201C6.13495 -0.0908772 5.54133 -0.0921428 5.17439 0.273013L0.275765 5.14801C0.275485 5.14829 0.27525 5.14862 0.274922 5.1489C-0.0922503 5.51537 -0.0910773 6.11087 0.27497 6.47612Z"
              fill="#2385B7"
            />
          </svg>
          <span>Accueil</span>
        </a>
      </div>
    </div>
  );
}
