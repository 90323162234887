import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { Card, Col, Modal, Row, Button } from "react-bootstrap";
import { fr } from "date-fns/locale";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  useAddSessionMutation,
  useGetSessionsByDateQuery,
  useSubscribeToSessionMutation,
} from "../../../features/sessions/sessionsAPISlice";
import moment from "moment";
import { useDispatch } from "react-redux";
import { apiSlice } from "../../../api/apiSlice";
import {
  useGetAllDoctorsQuery,
  useGetDoctorSessionsByDateQuery,
} from "../../../features/doctors/doctorsAPISlice";
import Select from "react-select";
import DateTimePicker from "../../components/Forms/Pickers/MetarialDateAndTime";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useUpdatePatientInfoMutation } from "../../../features/patients/patientsAPISlice";
import { useFilterGroupsQuery } from "../../../features/groups/groupsAPISlice";

const Calendar = () => {
  const eventClick = (eventClick) => {
    setEditableEvent(eventClick.event);
    setEditModal(true);
  };
  const navigate = useNavigate();
  let [startDate, setStartDate] = useState(moment().startOf("week").format());
  let [endDate, setEndDate] = useState(moment().endOf("week").format());
  const [editModal, setEditModal] = useState(false);
  const [editableEvent, setEditableEvent] = useState(null);
  const [user, _, subscriptions] = useOutletContext();
  const [events, setEvents] = useState([]);

  const groupFilter = () => {
    let obj = {
      $or: [
        {
          date_1: {
            $gte: moment(startDate).format(),
            $lte: moment(endDate).format(),
          },
        },
        {
          date_2: {
            $gte: moment(startDate).format(),
            $lte: moment(endDate).format(),
          },
        },
        {
          date_3: {
            $gte: moment(startDate).format(),
            $lte: moment(endDate).format(),
          },
        },
        {
          date_4: {
            $gte: moment(startDate).format(),
            $lte: moment(endDate).format(),
          },
        },
        {
          date_5: {
            $gte: moment(startDate).format(),
            $lte: moment(endDate).format(),
          },
        },
        {
          date_6: {
            $gte: moment(startDate).format(),
            $lte: moment(endDate).format(),
          },
        },
      ],
    };
    if (user.role === "ADMIN") {
      return obj;
    } else {
      return {
        ...obj,
        participants: user._id,
      };
    }
  };

  const { data: sessions, isLoading: sessionIsLoading } =
    useGetSessionsByDateQuery({
      role: user.role,
      id: user._id,
      startDate,
      endDate,
    });

  const { data: groups } = useFilterGroupsQuery({
    filter: groupFilter(),
    page: 0,
    limit: 0,
  });

  const handleDateChange = (arg) => {
    setStartDate(moment(arg.start).format());
    setEndDate(moment(arg.end).format());
  };

  const isParticipant = (participants) => {
    return (
      participants[0]._id
        ? participants.reduce((acc, curr) => [...acc, curr._id], [])
        : participants
    ).includes(user._id);
  };

  const handelEvents = () => {
    let eventsArray = [];

    if (sessions && sessions.sessions && sessions.sessions.length > 0) {
      eventsArray = [
        ...eventsArray,
        ...sessions.sessions.map((elt) => ({
          start: moment(elt.startDate).format(),
          id: elt._id,
          eventType: "Visio_Indiv",
          start: elt.startDate,
          end: elt.endDate,
          participants: elt.participants,
          description: elt.description,
          title: "séance individuelle",
        })),
      ];
    }

    if (groups && groups.groups && groups.groups.length) {
      for (let grp of groups.groups) {
        let days = Object.keys(grp).filter((elt) => elt.includes("date_"));
        for (let day of days) {
          eventsArray = [
            ...eventsArray,
            {
              start: grp[day],
              end: moment(grp[day]).add(1.5, "hours"),
              title: `${grp.title} (Séance ${day.replace("date_", "")})`,
              id: grp._id,
              eventType: grp.type || "Visio_Grouped",
              participants: grp.participants,
              description: grp.description,
            },
          ];
        }
      }
    }

    setEvents(
      eventsArray.map((elt) => {
        return {
          ...elt,
          className: "cal-event",
          title: elt.title,
          backgroundColor: "#0C5286",
        };
      })
    );
  };

  useEffect(() => {
    if (sessions || groups) {
      handelEvents();
    }
  }, [sessions, groups]);

  return (
    <>
      <Card>
        <Card.Body>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            initialView="timeGridWeek"
            editable={false}
            selectable={false}
            dayMaxEvents={true}
            weekends={false}
            events={events}
            eventClick={eventClick}
            selectAllow={false}
            locales={[fr]}
            buttonText={{
              today: "Aujourd'hui",
              month: "Mois",
              week: "Semaine",
              day: "Jour",
            }}
            lazyFetching={sessionIsLoading}
            datesSet={handleDateChange}
            nowIndicator
          />
        </Card.Body>
      </Card>
      {editableEvent && (
        <Modal className="modal fade" show={editModal}>
          <div className="modal-content">
            <div className="modal-header text-center justify-content-center">
              <h5 className="modal-title">
                {editableEvent.extendedProps.eventType === "GROUPED"
                  ? "Groupe"
                  : "Séance"}
              </h5>
              <Button
                variant=""
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => setEditModal(false)}
              >
                <span>×</span>
              </Button>
            </div>
            <div className="modal-body">
              <div className="row d-flex justify-content-center event-card">
                {editableEvent.title && (
                  <div className="col-12">
                    <h2 className="event-card-title">
                      <i className="fa-solid fa-calendar-plus"></i>
                      {editableEvent.title}
                    </h2>
                  </div>
                )}

                <div className="col-12 event-card-date">
                  <i className="fa-solid fa-clock"></i>
                  <p className="event-card-date-start">
                    {moment(editableEvent.start).format("DD-MM-yyyy HH:mm")}
                  </p>
                </div>

                {editableEvent.extendedProps.description && (
                  <div className="col-12 event-card-description">
                    <i className="fa-solid fa-comment"></i>
                    <p className="event-card-description-text">
                      {editableEvent.extendedProps.description}
                    </p>
                  </div>
                )}

                {editableEvent.extendedProps.eventType && (
                  <>
                    {editableEvent.extendedProps.eventType.includes(
                      "Visio"
                    ) && (
                      <div className="col-12 event-card-description">
                        <i className="fa-solid fa-users-rectangle"></i>
                        <p className="event-card-description-text">Visio</p>
                      </div>
                    )}
                    {editableEvent.extendedProps.eventType.includes(
                      "OnSite"
                    ) && (
                      <div className="col-12 event-card-description">
                        <i className="fa-solid fa-users-rectangle"></i>
                        <p className="event-card-description-text">Sur place</p>
                      </div>
                    )}
                  </>
                )}

                <div className="col-12 event-card-participants">
                  <i className="fa-solid fa-calendar"></i>
                  <ul className="event-card-participants-list">
                    {(editableEvent.extendedProps.participants || []).map(
                      (elt, key) => (
                        <li
                          key={key}
                          className="event-card-participants-list-item"
                        >
                          {elt.firstName || elt.lastName
                            ? `${elt.firstName || ""} ${elt.lastName || ""}`
                            : elt.email}
                        </li>
                      )
                    )}
                  </ul>
                </div>
                {editableEvent.extendedProps.eventType &&
                  ["Visio_Grouped", "Visio_Indiv"].includes(
                    editableEvent.extendedProps.eventType
                  ) && (
                    <button
                      className="event-card-link-button-cal"
                      onClick={() => {
                        if (
                          moment(editableEvent.start).get("day") !==
                          moment().get("day")
                        ) {
                          toast.warning(
                            "Si la réunion n'a pas lieu aujourd'hui, le lien sera désactivé!"
                          );
                          return;
                        }
                        navigate(`/consultation/${editableEvent.id}`);
                      }}
                    >
                      Lien vers la vidéoconférence
                    </button>
                  )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Calendar;
