import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

let initialState = {
  user: null,
  token: null,
  subscriptions: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, token, subscriptions } = action.payload;
      if (user) {
        state.user = user;
      }
      if (token) {
        state.token = token;
      }
      if (subscriptions && subscriptions.length > 0) {
        state.subscriptions = subscriptions;
      }
    },
    resetCredentials: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

const { setCredentials, resetCredentials } = authSlice.actions;

export default authSlice.reducer;

const getCurrentUser = (state) => state.auth.user;
const getCurrentUserToken = (state) => state.auth.token;
const getCurrentUserPack = (state) => state.auth.token;

export {
  setCredentials,
  resetCredentials,
  getCurrentUserToken,
  getCurrentUser,
  getCurrentUserPack,
};
