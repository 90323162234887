import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { toast } from "react-toastify";
import miniLogo from "../../images/LOGO_IMS_MINI.png";

const conf = config[process.env.NODE_ENV];

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const sendResetRequest = async () => {
    try {
      const { data } = await axios.post(`${conf.API_URL}/auth/forgotPassword`, {
        email: email,
      });

      if (data.email) {
        toast.success(
          "Si un compte avec l'adresse e-mail existe déjà, un e-mail lui sera envoyé."
        );
      } else {
        toast.warn(
          "Oups, un problème est survenu, veuillez réessayer plus tard."
        );
      }

      setTimeout(() => {
        navigate(-1);
      }, 2000);
    } catch (error) {
      console.log("sendResetRequest : ", error);
    }
  };
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
      <div className="row justify-content-center h-100 align-items-center auth-card">
          <div className="col-md-6 auth-card-content">
            <div className="authincation-content">
              <div className="row no-gutters">
              <div className="col-xl-12 auth-card-logo">
                  <img src={miniLogo} alt="" />
                </div>
                <div className="col-xl-12">
                  <div className="auth-form">
                    <h4 className="text-center mb-4">Mot de passe oublié</h4>
                    <div className="form-group">
                      <label>
                        <strong>Email</strong>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        defaultValue={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="text-center">
                      <input
                        type="submit"
                        value="ENVOYER"
                        className="btn btn-primary btn-block text-white"
                        onClick={async () => {
                          await sendResetRequest();
                        }}
                      />
                    </div>
                    <div className="row justify-content-center m-3">
                      <Link
                        to={-1}
                        className={"btn-link d-flex align-items-center"}
                      >
                        Retour
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
