import React, { useState } from "react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";

function BasicDateAndTimePicker({
  label = "",
  selectedDate,
  setSelectedDate = null,
  className,
  disabled = false,
}) {
  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      adapterLocale={fr}
      locale={fr}
    >
      <DateTimePicker
        autoOk
        label={label}
        clearable
        format="dd/MM/yyyy HH:mm"
        value={selectedDate}
        onChange={setSelectedDate}
        className={className}
        InputProps={{
          disableUnderline: true,
        }}
        okLabel="ok"
        clearLabel="Vider"
        todayLabel="Aujourd'hui"
        cancelLabel="Annuler"
        showTodayButton
        disabled={disabled}
      />
    </MuiPickersUtilsProvider>
  );
}

export default BasicDateAndTimePicker;
