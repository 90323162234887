import { useSelector } from "react-redux";
import {  Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const { user, token, subscriptions } = useSelector((state) => state.auth);
  if (!token) {
    // return <Navigate to="/non-autorise" />;
    return <Navigate to="/login" />;
  }

  return <Outlet context={[user, token, subscriptions]} />;
};
export default ProtectedRoute;
