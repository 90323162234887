import { apiSlice } from "../../api/apiSlice";

export const invoicesAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllInvoices: builder.query({
      query: (page = 1, limit = 10) => ({
        url: `/packSubscription/filter?page=${page}&limit=${limit}`,
      }),
      providesTags: ["Invoices"],
    }),
    getInvoiceById: builder.query({
      query: (id) => {
        return {
          url: `/packSubscription/${id}`,
        };
      },
    }),
    getInvoiceByParticipant: builder.query({
      query: ({ filter, page = 1, limit = 10, sort }) => {
        return {
          url: `/packSubscription/filter?page=${page}&limit=${limit}`,
          method: "POST",
          body: {
            filter,
            sort,
          },
        };
      },
      providesTags: ["InvoiceByParticipant"],
    })
  }),
});

export const {
  useGetAllInvoicesQuery,
  useGetInvoiceByIdQuery,
  useGetInvoiceByParticipantQuery
} = invoicesAPISlice;
