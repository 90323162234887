import React, { Fragment, useState, useRef, useEffect } from "react";
import { Table, Pagination } from "react-bootstrap";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import avatarEmpty from "../../../images/avatar_empty.png";

const ProfileDatatable = ({
  data,
  total,
  endpoint = null,
  setDeleteModal = null,
  setEditModal = null,
  setID = null,
  setViewModal = null,
  omitDelete = false,
  omitEdit = false,
  badgeColIndex = null,
  badgeMapping = null,
  directUrl = false,
  CutomEditButton = null,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(
    searchParams.get("page") ? parseInt(searchParams.get("page")) : 1
  );

  useEffect(() => {
    setSearchParams((searchParams) => {
      searchParams.set("page", currentPage);
      searchParams.set("limit", 10);
      return searchParams;
    });
  }, [currentPage]);

  useEffect(() => {
    if (!searchParams.get("page")) {
      setSearchParams((searchParams) => {
        searchParams.set("page", 1);
        return searchParams;
      });
    }
    if (!searchParams.get("limit")) {
      setSearchParams((searchParams) => {
        searchParams.set("limit", 10);
        return searchParams;
      });
    }
    if (data.data.length === 0) {
      setSearchParams((searchParams) => {
        searchParams.set("page", 1);
        searchParams.set("limit", 10);
        return searchParams;
      });
      setCurrentPage(1);
    }
  }, []);

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  };

  return (
    <div id="example_wrapper" className="dataTables_wrapper">
      <Table responsive className="w-100 display dataTable">
        <thead>
          <tr role="row">
            {data.columns
              .filter((elt) => elt !== "_id")
              .map((d, i) => (
                <th key={i}>{d}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data.data.map((elt, i) => {
            let { _id, url, ...rest } = elt;
            let display = Object.values(rest);
            return (
              <tr key={i}>
                {[
                  ...display,
                  <span className="d-flex justify-content-start">
                    {!omitEdit && (
                      <>
                        {url ? (
                          <span
                            onClick={() => {
                              setViewModal(true);
                              setID(directUrl ? url : _id);
                            }}
                            className="btn btn-primary shadow btn-xs sharp mr-1"
                          >
                            <i className="fa fa-eye"></i>
                          </span>
                        ) : endpoint ? (
                          <>
                            {CutomEditButton ? (
                              <CutomEditButton elt={elt}/>
                            ) : (
                              <Link
                                to={`/${endpoint}/details/${_id}`}
                                className="btn btn-primary shadow btn-xs sharp mr-1"
                              >
                                <i className="fa fa-pen-to-square"></i>
                              </Link>
                            )}
                          </>
                        ) : (
                          <span
                            onClick={() => {
                              setEditModal(true);
                              setID(elt);
                            }}
                            className="btn btn-primary shadow btn-xs sharp mr-1"
                          >
                            <i className="fa fa-eye"></i>
                          </span>
                        )}
                      </>
                    )}

                    {!omitDelete && (
                      <Link
                        onClick={() => {
                          setDeleteModal(true);
                          setID(_id);
                        }}
                        className="btn btn-danger shadow btn-xs sharp"
                      >
                        <i className="fa fa-trash"></i>
                      </Link>
                    )}
                  </span>,
                ].map((info, i) => (
                  <Fragment key={i}>
                    <td>
                      {i === 0 ? (
                        <>
                          {isValidUrl(info) ? (
                            <img
                              className="rounded-circle table-user-image"
                              width="35"
                              src={info || avatarEmpty}
                              alt=""
                            />
                          ) : (
                            <Fragment>{info}</Fragment>
                          )}
                        </>
                      ) : Array.isArray(info) ? (
                        info.map((elt, infoKey) => (
                          <Fragment key={infoKey}>
                            <span>{elt.label}</span>
                            <br></br>
                          </Fragment>
                        ))
                      ) : (
                        <span
                          className={
                            i === badgeColIndex
                              ? `badge light badge-${badgeMapping[info]}`
                              : ""
                          }
                        >
                          {info}
                        </span>
                      )}
                    </td>
                  </Fragment>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between align-items-center mt-3">
        <div className="dataTables_paginate paging_simple_numbers">
          <Pagination
            className="pagination-primary pagination-circle"
            size="lg"
          >
            {currentPage > 1 && (
              <li
                className="page-item page-indicator "
                onClick={() =>
                  currentPage > 1 && setCurrentPage(currentPage - 1)
                }
              >
                <Link className="page-link" to="#">
                  <i className="la la-angle-left" />
                </Link>
              </li>
            )}

            {[...Array(parseInt(total))].map((_, i) => (
              <Pagination.Item
                className={currentPage === i + 1 ? "active" : ""}
                onClick={() => setCurrentPage(i + 1)}
                key={i}
              >
                {i + 1}
              </Pagination.Item>
            ))}
            {currentPage < total && (
              <li
                className="page-item page-indicator"
                onClick={() =>
                  currentPage + 1 <= parseInt(total) &&
                  setCurrentPage(currentPage + 1)
                }
              >
                <Link className="page-link" to="#">
                  <i className="la la-angle-right" />
                </Link>
              </li>
            )}
          </Pagination>
        </div>
      </div>
    </div>
  );
};

export default ProfileDatatable;
